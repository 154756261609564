import {
  Button,
  Checkbox,
  DateSelect,
  FileUploader,
  Input,
  Select
} from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IdDocument } from 'types/actions/account';
import { maxUploadSize } from 'utilities/constants';
import { setCheckboxFieldProps, setFieldProps } from 'utilities/formHelpers';

import { idTypes, nonUSResidentIds } from '../../contents';
import { getIdTypeLabel } from '../../helpers';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
  existingDocument: IdDocument;
  idType?: string;
  isSecondaryAccountHolder?: boolean;
  onRemove?: () => void;
  onUpload?: (file: File) => void;
  residenceStatus?: string;
  review?: boolean;
  uploading?: boolean;
}

const IdentificationDocumentFields = <T extends FieldValues>({
  control,
  errors,
  existingDocument,
  idType,
  isSecondaryAccountHolder,
  onRemove,
  onUpload,
  residenceStatus,
  review,
  uploading
}: Props<T>) => {
  const navigate = useNavigate();

  const [hasNewDoc, setHasNewDoc] = useState(false);
  const fieldPrefix = isSecondaryAccountHolder
    ? 'investment_account.account_holder_2_'
    : '';

  const acceptedFileTypes = ['JPG', 'PNG'];
  const expirationDateFormatted =
    existingDocument?.expiry &&
    moment.utc(existingDocument.expiry).format('MM/DD/YYYY');
  const documentType =
    existingDocument?.type && getIdTypeLabel({ type: existingDocument.type });

  const handleEdit = () => {
    navigate('/accounts/wizard/identification_document');
  };

  const handleRemove = () => {
    setHasNewDoc(true);
    onRemove();
  };

  const handleUpload = file => {
    setHasNewDoc(true);
    onUpload(file);
  };

  return (
    <>
      {review && <h5>Personal Identification</h5>}
      <Row className="margin-row">
        <Col md={12}>
          <Controller
            control={control}
            name={`${fieldPrefix}id_document` as Path<T>}
            render={({ field }) => (
              <FileUploader
                {...setFieldProps(field, errors)}
                acceptedFileTypes={acceptedFileTypes}
                existingFile={!hasNewDoc && existingDocument}
                hideRemove={review}
                maxSize={maxUploadSize}
                onRemove={handleRemove}
                showImage={!review}
                upload={handleUpload}
                uploading={uploading}
              />
            )}
          />
        </Col>
      </Row>
      {review ? (
        <Row className="margin-row">
          <Col md={4}>
            <div className="text-label">Document Type</div>
            <div className="text-dark margin-xx">{documentType}</div>
          </Col>
          <Col md={4}>
            <div className="text-label">Document Expiration Date</div>
            <div className="text-dark margin-x">{expirationDateFormatted}</div>
          </Col>
          <Col md={4}>
            <Button
              onClick={handleEdit}
              style={{ width: '160px' }}
              variant="outlined"
            >
              Edit
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Controller
            control={control}
            name={`${fieldPrefix}id_type` as Path<T>}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Document Type"
                options={
                  residenceStatus === 'Other'
                    ? nonUSResidentIds.map(key => ({
                        label: key.label,
                        value: key.value
                      }))
                    : idTypes.map(key => ({
                        label: key.label,
                        value: key.value
                      }))
                }
              />
            )}
          />

          <Controller
            control={control}
            name={`${fieldPrefix}id_expiration` as Path<T>}
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                futureDate
                label="Expiration Date"
              />
            )}
          />

          {isSecondaryAccountHolder && (
            <Controller
              control={control}
              name={`${fieldPrefix}dob` as Path<T>}
              render={({ field }) => (
                <DateSelect
                  {...setFieldProps(field, errors)}
                  label="Date of Birth"
                />
              )}
            />
          )}

          {idType === 'passport' && (
            <Controller
              control={control}
              name={`${fieldPrefix}passport` as Path<T>}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Passport Number"
                />
              )}
            />
          )}

          <Controller
            control={control}
            name={`${fieldPrefix}confirm_valid_id` as Path<T>}
            render={({ field }) => (
              <Checkbox
                {...setCheckboxFieldProps(field, errors)}
                className="margin-xx"
                label="I confirm that my identification document is not expired and the document is a color-copy."
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default IdentificationDocumentFields;
