import {
  GenerateAccountStatementPayload,
  GetAccountResponse,
  GetDashboardResponse,
  GetInvestmentResponse
} from 'types/actions/portfolio';
import { AsyncActionCreator } from 'types/redux';

export const GET_DASHBOARD = 'portfolio/GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'portfolio/GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'portfolio/GET_DASHBOARD_FAIL';

export const GET_ACCOUNT = 'portfolio/GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'portfolio/GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAIL = 'portfolio/GET_ACCOUNT_FAIL';

export const CLEAR_ACCOUNT = 'portfolio/CLEAR_ACCOUNT';

export const GET_INVESTMENT = 'portfolio/GET_INVESTMENT';
export const GET_INVESTMENT_SUCCESS = 'portfolio/GET_INVESTMENT_SUCCESS';
export const GET_INVESTMENT_FAIL = 'portfolio/GET_INVESTMENT_FAIL';

export const CLEAR_INVESTMENT = 'portfolio/CLEAR_INVESTMENT';

export const GENERATE_ACCOUNT_STATEMENT =
  'portfolio/GENERATE_ACCOUNT_STATEMENT';
export const GENERATE_ACCOUNT_STATEMENT_SUCCESS =
  'portfolio/GENERATE_ACCOUNT_STATEMENT_SUCCESS';
export const GENERATE_ACCOUNT_STATEMENT_FAIL =
  'portfolio/GENERATE_ACCOUNT_STATEMENT_FAIL';

export const CLEAR_ACCOUNT_STATEMENT = 'portfolio/CLEAR_ACCOUNT_STATEMENT';

export function getDashboard(): AsyncActionCreator<GetDashboardResponse> {
  return {
    promise: client => client.get<GetDashboardResponse>('/mkt/portfolio'),
    types: [GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAIL]
  };
}

export function getAccount(id): AsyncActionCreator<GetAccountResponse> {
  return {
    promise: client =>
      client.get<GetAccountResponse>(`/mkt/portfolio/accounts/${id}`),
    types: [GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAIL]
  };
}

export function clearAccount() {
  return {
    type: CLEAR_ACCOUNT
  };
}

export function getInvestment(id): AsyncActionCreator<GetInvestmentResponse> {
  return {
    promise: client =>
      client.get<GetInvestmentResponse>(`/mkt/portfolio/investments/${id}`),
    types: [GET_INVESTMENT, GET_INVESTMENT_SUCCESS, GET_INVESTMENT_FAIL]
  };
}

export function clearInvestment() {
  return {
    type: CLEAR_INVESTMENT
  };
}

export function generateAccountStatement(
  data: GenerateAccountStatementPayload
) {
  return {
    promise: client => client.post('/mkt/users/statement', { data }),
    types: [
      GENERATE_ACCOUNT_STATEMENT,
      GENERATE_ACCOUNT_STATEMENT_SUCCESS,
      GENERATE_ACCOUNT_STATEMENT_FAIL
    ]
  };
}

export function clearAccountStatement() {
  return {
    type: CLEAR_ACCOUNT_STATEMENT
  };
}
