import { Button, Card } from '@equitymultiple/react-eui';
import React from 'react';

import * as sharedStyles from '../../SettingsShared.module.scss';
import * as styles from './PreferenceCenter.module.scss';

type Props = { email: string };

const PreferenceCenter = ({ email }: Props) => {
  return (
    <div data-testid="preferencesTab">
      <Card className={sharedStyles.settingsCard}>
        <h6>Communication Preferences</h6>
        <p>
          How should we stay in touch with you? You may return here to update
          your communication preferences at any time.
        </p>
        <Button
          className={styles.button}
          href={`${process.env.REACT_APP_COMMUNICATION_PREFERENCE_URL}/?email=${email}`}
          target="_blank"
        >
          Edit Communication Preferences
        </Button>
      </Card>
      <Card className={sharedStyles.settingsCard}>
        <h6>Investment Preferences</h6>
        <p>
          What type of real estate investments best fit your strategy? You may
          return here to update your investment preferences at any time.
        </p>
        <Button
          className={styles.button}
          href={`${process.env.REACT_APP_INVESTMENT_PREFERENCE_URL}/?email=${email}`}
          target="_blank"
        >
          Edit Investment Preferences
        </Button>
      </Card>
    </div>
  );
};

export default PreferenceCenter;
