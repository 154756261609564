import { LoadPostsResponse } from 'types/actions/posts';
import { AsyncActionCreator } from 'types/redux';

export const LOAD_POSTS = 'post/LOAD_POSTS';
export const LOAD_POSTS_BACKGROUND = 'post/LOAD_POSTS_BACKGROUND';
export const LOAD_POSTS_SUCCESS = 'post/LOAD_POSTS_SUCCESS';
export const LOAD_POSTS_APPEND_SUCCESS = 'post/LOAD_POSTS_APPEND_SUCCESS';
export const LOAD_POSTS_FAIL = 'post/LOAD_POSTS_FAIL';

export const READ_POSTS = 'post/READ_POSTS';
export const READ_POSTS_SUCCESS = 'post/READ_POSTS_SUCCESS';
export const READ_POSTS_FAIL = 'post/READ_POSTS_FAIL';

export function loadPosts(postArgs?): AsyncActionCreator<LoadPostsResponse> {
  const postQuery = {
    labels: postArgs?.labels || '',
    offeringIds: postArgs?.offeringIds || '',
    page: postArgs?.page || 1,
    pageSize: postArgs?.pageSize || 50,
    status: postArgs?.status || ''
  };
  const { labels, offeringIds, page, pageSize, status } = postQuery;

  const queryString = `page_size=${pageSize}&page=${page}&status=${status}&labels=${labels}&offering_ids=${offeringIds}`;

  // If on the first page, show the loader and replace all posts in the store
  // If on a later page, meaning we're loading via infite scroll, load in the background and append posts to the store
  const loadAction = page > 1 ? LOAD_POSTS_BACKGROUND : LOAD_POSTS;
  const loadSuccessAction =
    page > 1 ? LOAD_POSTS_APPEND_SUCCESS : LOAD_POSTS_SUCCESS;
  return {
    promise: client =>
      client.get<LoadPostsResponse>(`/mkt/posts?${queryString}`),
    types: [loadAction, loadSuccessAction, LOAD_POSTS_FAIL]
  };
}

export function markPostsRead(data) {
  return {
    promise: client =>
      client.post('/mkt/posts/read', {
        data
      }),
    types: [READ_POSTS, READ_POSTS_SUCCESS, READ_POSTS_FAIL]
  };
}
