import { Breadcrumbs, Card } from '@equitymultiple/react-eui';
import { Page } from 'containers/Portfolio/types';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { DashboardMetrics, InvestmentMetrics } from 'types/actions/portfolio';
import { ClosingStage } from 'types/api/closing';

import ClosingStageLabel from '../../../../components/ClosingStageLabel/ClosingStageLabel';
import utils from '../../../../utilities/utils';
import { getAccountDescription } from '../../helpers';
import AccountStatementModal from '../AccountStatementModal/AccountStatementModal';
import * as styles from './Overview.module.scss';

interface Props {
  account?: {
    id: number;
    type: string;
  };
  className?: string;
  closingStage?: ClosingStage;
  heading: string;
  investmentMetrics?: InvestmentMetrics;
  loading: boolean;
  metrics?: DashboardMetrics;
  page: Page;
  user?: {
    first_name: string;
    investor_profile: {
      stage: string;
    };
  };
}

const Overview = ({
  account,
  className = '',
  closingStage,
  heading,
  investmentMetrics,
  loading,
  metrics,
  page,
  user
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const renderMetric = (label, value) => {
    const isBalance = ['Capital Balance', 'Capital Invested'].includes(label);
    const isPercentage = ['Annualized Return', 'Return'].includes(label);

    let formattedValue = null;
    if (value) {
      if (isPercentage) formattedValue = value + '%';
      else formattedValue = utils.formatCurrency(value);
    }

    const placeholder = isPercentage ? '0%' : '$0';

    let valueClassName = '';
    if (value && !isBalance)
      valueClassName = value > 0 ? styles.positive : styles.negative;

    return (
      <div className={`${styles.metric} ${isBalance ? styles.balance : ''}`}>
        <div className="text-label">{label}</div>
        <div className={styles.metricValue}>
          {loading ? (
            <Skeleton />
          ) : (
            <span className={valueClassName}>
              {formattedValue || placeholder}
            </span>
          )}
        </div>
      </div>
    );
  };

  const onDashboard = page === 'dashboard';
  const onAccount = page === 'account';
  const onInvestment = page === 'investment';
  const isExited = closingStage === 'exited';

  return (
    <Card className={`${styles.overview} ${className}`}>
      {onDashboard && (
        <AccountStatementModal open={modalOpen} toggleOpen={toggleModal} />
      )}
      <Container>
        <div className={styles.top}>
          <div className={`${styles.left} ${loading ? styles.loading : ''}`}>
            {!onDashboard && !loading && (
              <Breadcrumbs>
                <Link to="/portfolio">Portfolio Dashboard</Link>
                {onAccount ? (
                  <div>{getAccountDescription(account)}</div>
                ) : (
                  <Link to={`/portfolio/account/${account?.id}`}>
                    {getAccountDescription(account)}
                  </Link>
                )}
                {onInvestment && <div>{heading}</div>}
              </Breadcrumbs>
            )}

            {onDashboard && (
              <h6 className={styles.heading6}>Welcome, {user.first_name}</h6>
            )}
            <div className={styles.heading}>
              {loading && !onDashboard ? (
                <Skeleton />
              ) : (
                <h2 className={styles.headingText}>{heading}</h2>
              )}
              {onInvestment && !loading && (
                <span className={styles.closingStageLabel}>
                  <ClosingStageLabel stage={closingStage} />
                </span>
              )}
            </div>
          </div>
          {onDashboard && (
            <div className={styles.right}>
              <button
                className={`${styles.downloadButton} text-link underline`}
                disabled={loading || user.investor_profile.stage !== 'multiply'}
                onClick={toggleModal}
                type="button"
              >
                <i className="fa fa-download" />
                Account Statement
              </button>
            </div>
          )}
        </div>

        {onInvestment ? (
          <div className={`${styles.metricsWrap} ${styles.investment}`}>
            {isExited
              ? renderMetric(
                  'Capital Invested',
                  investmentMetrics?.capital_invested
                )
              : renderMetric(
                  'Capital Balance',
                  investmentMetrics?.capital_balance
                )}
            {renderMetric('Return', investmentMetrics?.returns)}
            {renderMetric('Distributions', investmentMetrics?.distributions)}
            {isExited
              ? renderMetric(
                  'Annualized Return',
                  investmentMetrics?.annualized_return
                )
              : renderMetric('Earnings', investmentMetrics?.earnings)}
          </div>
        ) : (
          <Row>
            <Col className={styles.currentColumn} md={6}>
              <h3>Current</h3>
              <div className={styles.metricsWrap}>
                {renderMetric(
                  'Capital Balance',
                  metrics?.current?.capital_balance
                )}
                {renderMetric('Return', metrics?.current?.returns)}
                {renderMetric('Distributions', metrics?.current?.distributions)}
                {renderMetric('Earnings', metrics?.current?.earnings)}
              </div>
            </Col>
            <Col className={styles.exitedColumn} md={6}>
              <h3>Exited</h3>
              <div className={styles.metricsWrap}>
                {renderMetric(
                  'Capital Invested',
                  metrics?.exited?.capital_invested
                )}
                {renderMetric('Return', metrics?.exited?.returns)}
                {renderMetric('Distributions', metrics?.exited?.distributions)}
                {renderMetric(
                  'Annualized Return',
                  metrics?.exited?.annualized_returns
                )}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Card>
  );
};

export default Overview;
