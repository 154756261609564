import { Card, Tab, TabPanel, Tabs, TabsList } from '@equitymultiple/react-eui';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { User } from 'types/actions/auth';

import HeaderSimple from '../../components/HeaderSimple/HeaderSimple';
import { canAccessPage, twoFaSetupRequired } from '../../utilities/user';
import {
  Accreditation,
  PreferenceCenter,
  Profile,
  Security
} from './components';
import * as styles from './Settings.module.scss';

type Params = { tab: string };

interface Props {
  user: User;
}

const Settings = ({ user }: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const location = useLocation();

  const { tab } = params;

  useEffect(() => {
    document.title = 'Settings | EquityMultiple';

    if (!tab) navigate('/settings/profile');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!canAccessPage('settings', user)) navigate('/', { replace: true });

    const twoFaSetupIsRequired = twoFaSetupRequired(user);

    if (twoFaSetupIsRequired && tab !== 'security')
      navigate('/settings/security');
  }, [tab, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabClick = newTab => {
    navigate(`/settings/${newTab}`);
  };

  const twoFaSetupIsRequired = twoFaSetupRequired(user);
  const query = queryString.parse(location?.search);
  const accreditationSuccessModalAccountName = query?.accreditation_success;

  return (
    <div data-testid="userSettings">
      {twoFaSetupIsRequired && <HeaderSimple className={styles.headerNav} />}
      <Container className="container-narrow">
        <h1 className="margin-top-0">Settings</h1>
        <Tabs
          onChange={(_, newTab) => handleTabClick(newTab)}
          value={tab || 'profile'}
        >
          <Card className={styles.tabsCard}>
            <TabsList collapseBreakpoint="sm">
              <Tab data-testid="profileTabButton" value="profile">
                Profile
              </Tab>
              <Tab data-testid="accreditationTabButton" value="accreditation">
                Accreditation
              </Tab>
              <Tab data-testid="securityTabButton" value="security">
                Security
              </Tab>
              <Tab
                data-testid="preferenceCenterTabButton"
                value="preference_center"
              >
                Preference Center
              </Tab>
            </TabsList>
          </Card>

          <TabPanel value="profile">
            <Profile />
          </TabPanel>
          <TabPanel value="accreditation">
            <Accreditation
              successModalAccountName={accreditationSuccessModalAccountName}
            />
          </TabPanel>
          <TabPanel value="security">
            <Security />
          </TabPanel>
          <TabPanel value="preference_center">
            <PreferenceCenter email={user.email} />
          </TabPanel>
        </Tabs>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return { user: store.auth.user };
}

export default connect(mapStateToProps)(Settings);
