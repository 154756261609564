import React from 'react';
import { Link } from 'react-router-dom';
import { GetAllUserCapitalCallsResponse } from 'types/actions/capital-call';
import utils from 'utilities/utils';

type Props = {
  capitalCalls: GetAllUserCapitalCallsResponse;
};

const getRoute = (capitalCall, route) => {
  const optIn = capitalCall.investment?.capital_call_opt_in;
  const baseRoute = `/invest/${capitalCall.closing_id}/investment/${capitalCall.investment_id}`;
  const interestRoute = `${baseRoute}/capital_call/interest`;
  const fundRoute = `${baseRoute}/capital_call/${capitalCall.id}/fund`;
  const reviewRoute = `${baseRoute}/confirm`;
  const incompleteRoute = optIn ? fundRoute : interestRoute;

  switch (route) {
    case 'fund':
      return fundRoute;
    case 'incomplete':
      return incompleteRoute;
    case 'interest':
      return interestRoute;
    case 'review':
      return reviewRoute;
    default:
      return baseRoute;
  }
};

const getIntro = capitalCall => (
  <>
    The Sponsor, {capitalCall.offering.sponsor_name}, has requested a capital
    call for {capitalCall.offering.title}.{' '}
  </>
);

const getActiveCapitalCall = capitalCalls => {
  // Only one capital call to return
  if (capitalCalls.length === 1) return capitalCalls[0];

  // Multiple capital calls, remove capital calls the user opted out of and display the one with the earliest effective date
  const sortedCapitalCalls = capitalCalls.filter(
    capitalCall => capitalCall.investment?.capital_call_opt_in !== false
  );
  sortedCapitalCalls.sort(
    (a, b) =>
      new Date(a.effective_date).getTime() -
      new Date(b.effective_date).getTime()
  );
  return sortedCapitalCalls[0];
};

const renderSingleCapitalCall = capitalCall => {
  const optIn = capitalCall.investment?.capital_call_opt_in;
  const transactionInDraft = capitalCall.status === 'draft';
  const transactionFailed = capitalCall.status === 'failed';

  return optIn && !transactionInDraft ? (
    transactionFailed ? (
      <>
        Action required, please{' '}
        <Link
          className="text-link-light underline"
          to={getRoute(capitalCall, 'fund')}
        >
          update your capital call payment method!
        </Link>
      </>
    ) : (
      <Link
        className="text-link-light underline"
        to={getRoute(capitalCall, 'review')}
      >
        Review your Capital Call
      </Link>
    )
  ) : (
    <>
      {getIntro(capitalCall)}
      <Link
        className="text-link-light underline"
        to={getRoute(capitalCall, 'incomplete')}
      >
        Are you participating?
      </Link>
    </>
  );
};

const renderMultipleCapitalCalls = capitalCalls => {
  const capitalCallsCompleted = capitalCalls.filter(
    capitalCall =>
      capitalCall.investment?.capital_call_opt_in &&
      capitalCall.status !== 'draft'
  );

  const capitalCallsWithFailedTransactions = capitalCalls.filter(
    capitalCall => capitalCall.status === 'failed'
  );

  const capitalCallsRequiringResponse = capitalCalls.filter(
    capitalCall =>
      capitalCall.investment?.capital_call_opt_in === null ||
      (capitalCall.investment?.capital_call_opt_in &&
        capitalCall.status === 'draft')
  );

  if (capitalCallsRequiringResponse.length > 0) {
    return (
      <>
        {getIntro(capitalCalls[0])}Are you participating? <br />
        {capitalCallsRequiringResponse.map((capitalCall, index) => (
          <div key={capitalCall.id}>
            <Link
              className="text-link-light underline"
              to={getRoute(capitalCall, 'incomplete')}
            >
              Investment ({utils.dateFormat(capitalCall.closing.date_invested)})
            </Link>
            {index !== capitalCallsRequiringResponse.length - 1 && ', '}
          </div>
        ))}
      </>
    );
  } else if (capitalCallsWithFailedTransactions.length > 0) {
    return (
      <>
        Action required, please{' '}
        <Link
          className="text-link-light underline"
          to={getRoute(capitalCallsWithFailedTransactions[0], 'fund')}
        >
          update your capital call payment method!
        </Link>
      </>
    );
  } else {
    return (
      <>
        Review your Capital Calls:{' '}
        {capitalCallsCompleted.map((capitalCall, index) => (
          <div key={capitalCall.id}>
            <Link
              className="text-link-light underline"
              to={getRoute(capitalCall, 'review')}
            >
              Investment ({utils.dateFormat(capitalCall.effective_date)})
            </Link>
            {index !== capitalCallsCompleted.length - 1 && ', '}
          </div>
        ))}
      </>
    );
  }
};

const CapitalCallBanner = ({ capitalCalls }: Props) => {
  const activeCapitalCall = getActiveCapitalCall(capitalCalls);
  const investmentsInCapitalCall = capitalCalls.filter(
    capitalCall => capitalCall.offering_id === activeCapitalCall.offering_id
  );

  if (investmentsInCapitalCall.length > 1) {
    return renderMultipleCapitalCalls(investmentsInCapitalCall);
  } else {
    return renderSingleCapitalCall(activeCapitalCall);
  }
};

export default CapitalCallBanner;
