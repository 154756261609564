import { LoadUserUpdateAlerts } from 'types/actions/user-update-alerts';
import { AsyncActionCreator } from 'types/redux';

export const LOAD_UPDATE_ALERT = 'userUpdateAlert/LOAD';
export const LOAD_UPDATE_ALERT_SUCCESS = 'userUpdateAlert/LOAD_SUCCESS';
export const LOAD_UPDATE_ALERT_FAIL = 'userUpdateAlert/LOAD_FAIL';
export const SUBMIT_USER_UPDATE_ALERT =
  'userUpdateAlert/SUBMIT_USER_UPDATE_ALERT';
export const SUBMIT_USER_UPDATE_ALERT_SUCCESS =
  'userUpdateAlert/LOAD_UPDATE_ALERT_SUCCESS';
export const SUBMIT_USER_UPDATE_ALERT_FAIL =
  'userUpdateAlert/SUBMIT_USER_UPDATE_ALERT_FAIL';

export function loadUserUpdateAlerts(): AsyncActionCreator<LoadUserUpdateAlerts> {
  return {
    promise: client =>
      client.get<LoadUserUpdateAlerts>('/mkt/user_update_alerts'),
    types: [
      LOAD_UPDATE_ALERT,
      LOAD_UPDATE_ALERT_SUCCESS,
      LOAD_UPDATE_ALERT_FAIL
    ]
  };
}

export function updateUserUpdateAlert(userUpdateAlert) {
  return {
    promise: client =>
      client.put(`/mkt/user_update_alerts/${userUpdateAlert.id}`, {
        data: { user_update_alert: userUpdateAlert }
      }),
    types: [
      SUBMIT_USER_UPDATE_ALERT,
      SUBMIT_USER_UPDATE_ALERT_SUCCESS,
      SUBMIT_USER_UPDATE_ALERT_FAIL
    ]
  };
}
