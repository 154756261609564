import { AnyAction } from 'redux';

import * as actions from '../actions/offering-tax-statuses';
const initialState = {
  downloadingDocuments: false,
  loadedOfferingTaxStatuses: false,
  loadingOfferingTaxStatuses: false,
  offeringTaxStatusInvestmentAccounts: []
};

export default (state = initialState, action: AnyAction = { type: null }) => {
  switch (action.type) {
    case actions.FETCH_TAX_OFFERING_STATUSES:
      return {
        ...state,
        loadedOfferingTaxStatuses: false,
        loadingOfferingTaxStatuses: true
      };
    case actions.FETCH_TAX_OFFERING_STATUSES_SUCCESS:
      return {
        ...state,
        loadedOfferingTaxStatuses: true,
        loadingOfferingTaxStatuses: false,
        offeringTaxStatusInvestmentAccounts: action.result.investment_accounts,
        startOfSeason: action.result.start_of_season
      };
    case actions.FETCH_TAX_OFFERING_STATUSES_FAIL:
      return {
        ...state,
        loadedOfferingTaxStatuses: false,
        loadingOfferingTaxStatuses: false
      };

    case actions.DOWNLOAD_DOCUMENTS:
      return {
        ...state,
        downloadingDocuments: true
      };

    case actions.DOWNLOAD_DOCUMENTS_FAIL:
    case actions.DOWNLOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        downloadingDocuments: false
      };

    default:
      return state;
  }
};
