import { OfferingTaxStatusStep } from 'types/actions/offering-tax-stauses';

export const taxDocSteps: OfferingTaxStatusStep[] = [
  'initial_data_preparation',
  'tax_document_requested',
  'tax_document_received_and_under_review',
  'preparing_investor_tax_document',
  'tax_document_available'
];

export const getTaxDocStepInfo = (step: OfferingTaxStatusStep, type?) => {
  let stepNumber = 0;
  let message = '';
  switch (step) {
    case 'tax_document_requested':
      stepNumber = 2;
      message = 'Tax document requested';
      break;
    case 'tax_document_received_and_under_review':
      stepNumber = 3;
      message = 'Tax document received and under review';
      break;
    case 'preparing_investor_tax_document':
      stepNumber = 4;
      message = 'Preparing investor tax documents';
      break;
    case 'tax_document_available':
      stepNumber = 5;
      message = 'Tax document available';
      break;
    default:
      stepNumber = 1;
      message = 'Initial data preparation';
      break;
  }
  return type === 'message' ? message : stepNumber;
};
