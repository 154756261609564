import { Alert, Tooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';
import { LoadOfferingWithComponentsResponse } from 'types/actions/offerings';
import { Closing } from 'types/api/closing';

import InvestButton from '../InvestButton/InvestButton';
import * as styles from './Infobar.module.scss';

interface Props {
  closing: Closing;
  mustVerifyToInvest: boolean;
  offering: LoadOfferingWithComponentsResponse['offering'];
}

const Infobar = ({ closing, mustVerifyToInvest, offering }: Props) => {
  let addressList;
  let sponsorList;

  const alternateLocation = offering.alternate_location?.trim();
  const closingAllowsInvestment = closing?.is_investment_allowed;
  const closingInInterest = closing?.stage === 'interest';

  if (offering.addresses?.length > 0) {
    addressList = offering.addresses.map(address => (
      <ul key={address.address}>
        <li>{address.address}</li>
      </ul>
    ));
  }

  if (offering.sponsors?.length > 0) {
    sponsorList = offering.sponsors.map(sponsor => {
      return (
        <div className={styles.sponsor} key={sponsor.name}>
          <div className={styles.companyLogo}>
            <img alt={sponsor.name} src={sponsor.logo?.thumb?.url} />
          </div>
          <div className={styles.companyName}>{sponsor.name}</div>
        </div>
      );
    });
  }

  return (
    <div className={styles.infobar} data-testid="infobar">
      {closingInInterest && closingAllowsInvestment && !closing?.investment && (
        <Alert>
          <span>
            <strong>Want to invest in this offering?</strong> Indicate interest
            to get priority access to invest when funding becomes available. If
            we are unable to accommodate your investment in this series, we will
            prioritize investments on a first-come, first-served basis in
            subsequent series.
          </span>
        </Alert>
      )}
      {closingAllowsInvestment && mustVerifyToInvest && (
        <Alert data-testid="mustVerifyMessage" type="warning">
          <div>
            This offering is no longer accepting investments from investors that
            have not verified their accreditation. Please{' '}
            <Link
              className="text-link"
              data-testid="mustVerifyLink"
              to="/settings/accreditation"
            >
              verify your accreditation
            </Link>{' '}
            to invest.
          </div>
        </Alert>
      )}
      <div className={styles.sponsorRow}>{sponsorList}</div>
      <div className={styles.contentRow}>
        <div className={styles.addressWrap}>
          <Tooltip
            placement="top"
            tooltipContent={
              <div className={styles.tooltipContent}>
                {alternateLocation
                  ? addressList || alternateLocation
                  : offering.addresses?.[0] && addressList}
              </div>
            }
          >
            <div className={styles.address}>
              <i className="fa fa-map-marker" />
              {alternateLocation ||
                (offering.addresses?.[0]
                  ? offering.addresses[0].city +
                    ', ' +
                    offering.addresses[0].state
                  : 'unavailable')}
            </div>
          </Tooltip>
        </div>

        <InvestButton
          className={styles.investButton}
          mustVerifyToInvest={mustVerifyToInvest}
        />
      </div>
    </div>
  );
};

export default Infobar;
