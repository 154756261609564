import { Button, Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import { getUserAttributes } from 'containers/Profile/helpers';
import React, { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import {
  updatePersonalIdentification,
  updateUserProfile
} from 'redux/actions/user-settings';
import { User } from 'types/actions/auth';
import { UpdateUserProfilePayload } from 'types/actions/user-settings';
import { Dispatch } from 'types/redux';
import humane from 'utilities/humane';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';
import { InferType } from 'yup';

import * as sharedStyles from '../../SettingsShared.module.scss';
import AddressFields from '../forms/AddressFields';
import InvestorProfileFields from '../forms/InvestorProfileFields';
import PersonalIdentification from '../forms/PersonalIdentificationFields';
import ProfileFields from '../forms/ProfileFields';
import { profileSchema } from '../validation';

interface Props {
  disableSsn?: boolean;
  dispatch: Dispatch;
  loading?: boolean;
  user?: User;
}

export type ProfileFormValues = InferType<typeof profileSchema>;

const Profile = ({ disableSsn, dispatch, loading, user }: Props) => {
  const defaultValues = getUserAttributes(user) as ProfileFormValues;

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm({
    defaultValues: defaultValues,
    resolver: (data, context, options) =>
      yupResolver(profileSchema)(
        data,
        { ...context, formValues: data },
        options
      )
  });

  const residenceStatus = watch('investor_profile_attributes.residence_status');

  const onSubmit: SubmitHandler<ProfileFormValues> = useCallback(
    values => {
      const defaultIdFields = defaultValues.id_document;
      const idFieldChanged = Object.keys(defaultIdFields).some(field => {
        if (field === 'attachment') {
          return values.id_document.attachment instanceof File;
        } else {
          return defaultIdFields[field] !== values.id_document[field];
        }
      });

      const dispatchUpdateUserProfile = () => {
        const userValues = utils.cloneObject(values);
        delete userValues.id_document;
        dispatch(updateUserProfile(userValues as UpdateUserProfilePayload))
          .then(() => {
            humane.notice('Profile updated successfully');
            dispatch(loadAuth());
          })
          .catch(err => throwReactHookFormSubmissionErrors(err, setError));
      };

      if (idFieldChanged) {
        if (!(values.id_document.attachment instanceof File))
          delete values.id_document.attachment;

        const attachmentValues = {
          user: {
            id_document: values.id_document.attachment as File,
            id_expiration: values.id_document.expiry,
            id_type: values.id_document.type
          }
        };

        return dispatch(updatePersonalIdentification(attachmentValues))
          .then(() => {
            return dispatchUpdateUserProfile();
          })
          .catch(err => throwReactHookFormSubmissionErrors(err, setError));
      }

      return dispatchUpdateUserProfile();
    },
    [dispatch, setError, defaultValues]
  );

  return loading ? (
    <EMLoadingIcon />
  ) : (
    <Card className={sharedStyles.settingsCard} data-testid="profileTab">
      <form data-testid="profileSettings" onSubmit={handleSubmit(onSubmit)}>
        <ProfileFields
          control={control}
          disableSsn={disableSsn}
          errors={errors}
          residenceStatus={residenceStatus}
        />

        <AddressFields
          control={control}
          errors={errors}
          residenceStatus={residenceStatus}
        />

        <InvestorProfileFields
          control={control}
          errors={errors}
          userStage={user.investor_profile?.stage}
        />

        <PersonalIdentification
          control={control}
          defaultValues={defaultValues}
          errors={errors}
          isSubmitting={isSubmitting}
          setValue={setValue}
          watch={watch}
        />

        <FormError errors={errors} />
        <Button
          className="float-right margin-top-x"
          data-testid="profileSubmit"
          loading={isSubmitting}
          type="submit"
        >
          Save and Update
        </Button>
      </form>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    disableSsn: !!state.auth.user?.investor_profile?.ssn,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const connector = connect(mapStateToProps)(Profile);

export default connector;
