export const getSingleOwnerAccountLabels = accounts => {
  const singleOwnerAccountLabels = [];

  accounts.forEach(account => {
    if (account.status === 'created') {
      switch (account.type) {
        case 'entity':
          if (account.business_type === 'soleproprietorship')
            singleOwnerAccountLabels.push('Sole Proprietorship');
          if (account.business_type === 'single_member_llc')
            singleOwnerAccountLabels.push('Single-Member LLC');
          break;
        case 'individual':
          singleOwnerAccountLabels.push('Individual Account');
          break;
        case 'ira':
          singleOwnerAccountLabels.push('IRA');
          break;
      }
    }
  });

  const uniqueLabels = new Set(singleOwnerAccountLabels);
  return [...uniqueLabels].join(', ');
};
