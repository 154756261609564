import { Input, Select } from '@equitymultiple/react-eui';
import { stateOptions } from 'containers/Accounts/contents';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { setFieldProps } from 'utilities/formHelpers';

import { ProfileFormValues } from '../Profile/Profile';

interface Props {
  control: Control<ProfileFormValues>;
  errors: FieldErrors<ProfileFormValues>;
  residenceStatus?: string;
}

const AddressFields = ({ control, errors, residenceStatus }: Props) => {
  return (
    <>
      <h6>Personal Address</h6>
      <Row>
        <Col sm={6}>
          <Controller
            control={control}
            name="address_attributes.address"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address"
                maxLength={50}
                placeholder="Street Address"
              />
            )}
          />
        </Col>
        <Col sm={6}>
          <Controller
            control={control}
            name="address_attributes.address2"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2 (Optional)"
                maxLength={50}
                placeholder="Apartment number, etc."
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Controller
            control={control}
            name="address_attributes.city"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>

        <Col sm={4}>
          {residenceStatus === 'Other' ? (
            <Controller
              control={control}
              name="address_attributes.state"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="State / Province"
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name="address_attributes.state"
              render={({ field }) => (
                <Select
                  {...setFieldProps(field, errors)}
                  label="State"
                  options={stateOptions}
                />
              )}
            />
          )}
        </Col>
        <Col sm={4}>
          <Controller
            control={control}
            name="address_attributes.postal_code"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="99999"
                placeholder="Postal Code"
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddressFields;
