import {
  Address,
  DocumentIdentification,
  Experience,
  Identity,
  Objectives,
  ProfileName,
  ProfileStart,
  Tolerance
} from 'containers/Profile';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PaymentActivityRedirect from 'routerHelpers/PaymentActivityRedirect';
import ReferralRedirect from 'routerHelpers/ReferralRedirect';
import SignUpRedirect from 'routerHelpers/SignUpRedirect';

import {
  Activities,
  App,
  CancelInvestment,
  CapitalCall,
  ConfidentialityAgreement,
  DebtFundElections,
  DistributionReinvestmentElections,
  ForgotPassword,
  Fund,
  IndicateInterest,
  Invest,
  Invite,
  MyDocuments,
  NotFound,
  Offering,
  Portfolio,
  ResetPassword,
  Review,
  Setting,
  Sign,
  SignIn,
  STNElections,
  TaxTracker,
  UnlockInstructions,
  UserUpdateAlert,
  VerifyAccreditation
} from './containers';
import accountRoutes from './containers/Accounts/routes';
import {
  Account as PortfolioAccount,
  Investment as PortfolioInvestment
} from './containers/Portfolio/containers';
import {
  Accreditation,
  Continue,
  Discover,
  EmailVerify,
  Name,
  Password,
  Phone,
  Start
} from './containers/SignUp';
import {
  RequireAccreditedUser,
  RequireCompletedSignup,
  RequireLogin
} from './routerHelpers';

export const routerOptions = {
  future: {
    v7_relativeSplatPath: true,
    v7_startTransition: true
  }
};

const AppRouter = () => (
  <BrowserRouter {...routerOptions}>
    <App>
      <Routes>
        {
          // Start public routes
        }
        <Route element={<Start />} path="/users/invitation/accept" />
        <Route element={<Start />} path="/users/signup/start" />
        <Route element={<Password />} path="/users/signup/password" />
        <Route element={<Name />} path="/users/signup/name" />
        <Route element={<Accreditation />} path="/users/signup/accreditation" />
        {
          // Start sign up routes that require login
        }
        <Route element={<Continue />} path="/users/signup/continue" />
        <Route element={<Phone />} path="/users/signup/phone" />
        <Route element={<Discover />} path="/users/signup/discover" />
        <Route element={<EmailVerify />} path="/users/signup/email_verify" />
        {
          // End sign up routes that require login
        }
        <Route element={<Start />} path="/users/signup/:referral_token" />
        <Route
          element={<ReferralRedirect />}
          path="/users/sign_up/:referral_token"
        />
        <Route element={<SignUpRedirect />} path="/users/sign_up" />
        <Route element={<SignIn />} path="/users/sign_in" />
        <Route element={<UnlockInstructions />} path="/users/locked" />
        <Route element={<ForgotPassword />} path="/users/password/new" />
        <Route element={<ResetPassword />} path="/users/password/edit" />
        {
          // End public routes
        }
        <Route element={<RequireLogin />}>
          <Route element={<NotFound />} path="*" />
          <Route element={<Invest />} path="/" />
          <Route element={<Navigate replace to="/" />} path="/invest" />
          <Route
            element={<Offering />}
            path="/invest/:offering_id/closings/:closing_id"
          />
          <Route element={<Offering />} path="/invest/:offering_id" />
          <Route element={<Invite />} path="/users/invitation/new" />
          <Route element={<UserUpdateAlert />} path="/users/update_account" />
          {
            // Start profile routes
          }
          <Route element={<ProfileStart />} path="/users/profile/start" />
          <Route element={<Experience />} path="/users/profile/experience" />
          <Route element={<Objectives />} path="/users/profile/objectives" />
          <Route element={<Tolerance />} path="/users/profile/tolerance" />
          <Route element={<ProfileName />} path="/users/profile/name" />
          <Route element={<Identity />} path="/users/profile/kyc" />
          <Route element={<Address />} path="/users/profile/address" />
          <Route
            element={<DocumentIdentification />}
            path="/users/profile/document_identification"
          />
          {
            // End profile routes
          }
          <Route element={<RequireCompletedSignup />}>
            <Route element={<Activities />} path="/activity" />
            <Route element={<Portfolio />} path="/portfolio" />
            <Route
              element={<PortfolioAccount />}
              path="/portfolio/account/:account_id"
            />
            <Route
              element={<PortfolioInvestment />}
              path="/portfolio/account/:account_id/investment/:investment_id"
            />
            <Route
              element={<PaymentActivityRedirect />}
              path="/investments/:offering_id/:closing_id/cashflow/:investment_id"
            />
            <Route
              element={<IndicateInterest />}
              path="/invest/:closing_id/investment/new"
            />
            <Route
              element={<IndicateInterest />}
              path="/invest/:closing_id/investment/:investment_id/interest"
            />
            <Route
              element={<Sign />}
              path="/invest/:closing_id/investment/:investment_id/sign"
            />
            <Route
              element={<Fund />}
              path="/invest/:closing_id/investment/:investment_id/fund"
            />
            <Route
              element={<Fund />}
              path="/invest/:closing_id/investment/:investment_id/capital_call/:transaction_id/fund"
            />
            <Route
              element={<Review />}
              path="/invest/:closing_id/investment/:investment_id/confirm"
            />
            <Route
              element={<IndicateInterest />}
              path="/invest/:closing_id/investment/:investment_id/edit"
            />
            <Route
              element={<DebtFundElections />}
              path="/offerings/:offering_id/elections"
            />
            {
              // TODO: We aren't using the offering_id param and should remove it and update any external links
            }
            <Route
              element={<STNElections />}
              path="/offerings/:offering_id/elections/:investment_id"
            />
            <Route
              element={<DistributionReinvestmentElections />}
              path="/offerings/:offering_id/distribution_reinvestment"
            />
            <Route
              element={<CancelInvestment />}
              path="/invest/:closing_id/investment/:investment_id/cancel"
            />
            <Route
              element={<CapitalCall />}
              path="/invest/:closing_id/investment/:investment_id/capital_call/interest"
            />
            <Route
              element={<CapitalCall />}
              path="/invest/:closing_id/investment/:investment_id/capital_call/allocation"
            />
            <Route
              element={<CapitalCall />}
              path="/invest/:closing_id/investment/:investment_id/capital_call/thanks"
            />
            <Route
              element={<ConfidentialityAgreement />}
              path="/users/offerings/:offering_id/closings/:closing_id/confidentiality"
            />
            <Route
              element={<VerifyAccreditation />}
              path="/verify_accreditation/:reference_id"
            />
            <Route
              element={
                <RequireAccreditedUser>{accountRoutes}</RequireAccreditedUser>
              }
              path="/accounts/*"
            />
            <Route element={<TaxTracker />} path="/tax_tracker" />
            <Route element={<Setting />} path="/settings/:tab?" />
            <Route element={<MyDocuments />} path="/documents" />
          </Route>
          <Route path="/invest/:id/*" />
        </Route>
      </Routes>
    </App>
  </BrowserRouter>
);

export default AppRouter;
