import { Select } from '@equitymultiple/react-eui';
import {
  experienceOptions,
  objectivesOptions,
  reasonOptions,
  riskToleranceOptions
} from 'containers/Profile/content';
import { accreditationOptions } from 'containers/SignUp/content';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UserStage } from 'types/actions/auth';
import { setFieldProps } from 'utilities/formHelpers';

import { ProfileFormValues } from '../Profile/Profile';

interface Props {
  control: Control<ProfileFormValues>;
  errors: FieldErrors<ProfileFormValues>;
  userStage: UserStage;
}

const InvestorProfileFields = ({ control, errors, userStage }: Props) => {
  return (
    <>
      <h6>Investor Profile</h6>
      <p>My primary real-estate investing objective is:</p>
      <Controller
        control={control}
        name="investor_profile_attributes.investment_objectives"
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            className="margin-xx"
            options={objectivesOptions}
            placeholder="Select"
          />
        )}
      />

      <p>Investment Experience</p>
      <Controller
        control={control}
        name="investor_profile_attributes.investment_experience"
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            className="margin-xx"
            multi
            options={experienceOptions}
            placeholder="Select"
            showMultiLabelsBelow
          />
        )}
      />

      <p>What are you investing for?</p>
      <Controller
        control={control}
        name="investor_profile_attributes.investment_reason"
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            className="margin-xx"
            multi
            options={reasonOptions}
            placeholder="Select"
            showMultiLabelsBelow
          />
        )}
      />

      <p>My risk tolerance is:</p>
      <Controller
        control={control}
        name="investor_profile_attributes.risk_tolerance"
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            className="margin-xx"
            options={riskToleranceOptions}
            placeholder="Select"
          />
        )}
      />

      {userStage === 'activate' && (
        <>
          <p>Select what describes you best</p>
          <Controller
            control={control}
            name="investor_profile_attributes.accreditation"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                className="margin-xx"
                multi
                options={accreditationOptions}
                placeholder="Select"
                showMultiLabelsBelow
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default InvestorProfileFields;
