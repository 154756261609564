import { AnyAction } from 'redux';

import * as actions from '../actions/user-settings';
const initialState = {
  loading: false,
  loadingInvPref: true,
  loadingRecoveryCodes: false,
  profileValues: {},
  requestingCode: false,
  twoFactorRecoveryCodes: null,
  updatingInvPref: false,
  updatingPersonalIdentification: false,
  updatingUsername: false,
  updatingUserProfile: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.UPDATE_USERNAME:
      return {
        ...state,
        updatedUsername: false,
        updatingUsername: true
      };

    case actions.UPDATE_USERNAME_SUCCESS:
      return {
        ...state,
        unconfirmedEmail: action.result.unconfirmedEmail,
        updatedUsername: true,
        updatingUsername: false
      };

    case actions.UPDATE_USERNAME_FAIL:
      return {
        ...state,
        updatedUsername: false,
        updateUsernameError: action.error,
        updatingUsername: false
      };

    case actions.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordResult: action.result
      };

    case actions.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        updatePasswordError: action.error
      };

    case actions.UPDATE_USER_PROFILE:
      return {
        ...state,
        updatedUserProfile: false,
        updatingUserProfile: true
      };

    case actions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updatedUserProfile: true,
        updateUserProfileResult: action.result,
        updatingUserProfile: false
      };

    case actions.UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        updatedUserProfile: false,
        updateUserProfileError: action.error,
        updatingUserProfile: false
      };

    case actions.UPDATE_PERSONAL_IDENTIFICATION:
      return {
        ...state,
        updatingPersonalIdentification: true
      };

    case actions.UPDATE_PERSONAL_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        updatePersonalIdentificationResult: action.result,
        updatingPersonalIdentification: false
      };

    case actions.UPDATE_PERSONAL_IDENTIFICATION_FAIL:
      return {
        ...state,
        updatePersonalIdentificationError: action.error,
        updatingPersonalIdentification: false
      };

    case actions.REGENERATE_RECOVERY_CODES:
      return {
        ...state,
        loadingRecoveryCodes: true
      };

    case actions.REGENERATE_RECOVERY_CODES_FAIL:
      return {
        ...state,
        loadingRecoveryCodes: false
      };

    case actions.REGENERATE_RECOVERY_CODES_SUCCESS:
      return {
        ...state,
        loadingRecoveryCodes: false,
        twoFactorRecoveryCodes: action.result.backup_codes
      };

    case actions.CLEAR_RECOVERY_CODES:
      return {
        ...state,
        twoFactorRecoveryCodes: []
      };

    case actions.REQUEST_PHONE_VERIFICATION_CODE:
    case actions.VERIFY_PHONE_VERIFICATION_CODE:
      return {
        ...state,
        requestingCode: true
      };

    case actions.REQUEST_PHONE_VERIFICATION_CODE_SUCCESS:
    case actions.REQUEST_PHONE_VERIFICATION_CODE_FAIL:
    case actions.VERIFY_PHONE_VERIFICATION_CODE_FAIL:
      return {
        ...state,
        requestingCode: false
      };

    case actions.VERIFY_PHONE_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        requestingCode: false,
        twoFactorRecoveryCodes: action.result.backup_codes
      };

    default:
      return state;
  }
};
