import { ErrorBoundary } from '@equitymultiple/react-eui';
import React from 'react';
import { User } from 'types/actions/auth';
import { LoadOfferingWithComponentsResponse } from 'types/actions/offerings';

import { Component } from '../types';
import CanvasComponent from './CanvasComponent/CanvasComponent';

interface Props {
  components: Component[];
  offering: LoadOfferingWithComponentsResponse['offering'];
  user: User;
}

const Canvas = ({ components, offering, user }: Props) => {
  return (
    <div>
      <ErrorBoundary>
        {components?.map(component => (
          <CanvasComponent
            component={component}
            key={component.id}
            offering={offering}
            user={user}
          />
        ))}
      </ErrorBoundary>
    </div>
  );
};

export default Canvas;
