import {
  Alert,
  Button,
  Card,
  Modal,
  Select,
  StatusLabel,
  Tab,
  TabPanel,
  Tabs,
  TabsList
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import ResourceCard from 'components/ResourceCard/ResourceCard';
import { getAccountDescription } from 'containers/Portfolio/helpers';
import IconCheck from 'images/icons/check.svg';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  downloadTaxDocuments,
  loadTaxOfferingStatuses
} from 'redux/actions/offering-tax-statuses';
import {
  LoadOfferingTaxStatusesResponse,
  OfferingTaxStatusInvestmentAccount
} from 'types/actions/offering-tax-stauses';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { setFieldProps } from 'utilities/formHelpers';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import NoDocuments from './NoDocuments/NoDocuments';
import TaxDocumentTable from './TaxDocumentTable/TaxDocumentTable';
import * as styles from './TaxTracker.module.scss';
import { documentDownloadSchema } from './validation';

interface Props {
  accounts: LoadOfferingTaxStatusesResponse['investment_accounts'];
  dispatch: Dispatch;
  downloading: boolean;
  loading: boolean;
  startOfSeason: string;
}

const LoadingTabs = () =>
  Array.from({ length: 2 }, (_, index) => (
    <Tab key={index}>
      <div className={styles.accountTab}>
        <div>
          <Skeleton width={100} />
          <span className={styles.accountDescription}>
            <Skeleton />
          </span>
        </div>
      </div>
    </Tab>
  ));

const getAccountTabs = (accounts: OfferingTaxStatusInvestmentAccount[]) =>
  accounts?.map(account => {
    const docsCount = account.offering_tax_statuses.length;
    const availableDocsCount = account.offering_tax_statuses.filter(
      doc => doc.step === 'tax_document_available'
    ).length;
    const allDocsAvailable = docsCount === availableDocsCount;

    let labelColor = '#7f8083';
    if (availableDocsCount > 0) labelColor = '#fdb309';
    if (allDocsAvailable) labelColor = '#4caf50';

    return (
      <Tab
        data-testid={account.name}
        key={account.reference_id}
        value={account.reference_id.toString()}
      >
        <div className={styles.accountTab}>
          <div>
            {account.name}
            <span className={styles.accountDescription}>
              {getAccountDescription(account)}
            </span>
          </div>
          <StatusLabel
            color={labelColor}
            data-testid="statusLabel"
            label={
              allDocsAvailable ? (
                <IconCheck />
              ) : (
                `${availableDocsCount}/${docsCount}`
              )
            }
          />
        </div>
      </Tab>
    );
  });

const TaxTracker = ({
  accounts,
  dispatch,
  downloading,
  loading,
  startOfSeason
}: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch
  } = useForm({
    resolver: yupResolver(documentDownloadSchema)
  });

  const accountParam = searchParams.get('account');
  const selectedAccountIds = watch('accountIds');

  const selectedAccounts = accounts.filter(account =>
    selectedAccountIds?.includes(account.id)
  );

  const hasSelectedAccountIncompleteDocument = selectedAccounts.some(account =>
    account.offering_tax_statuses.some(
      status => status.step !== 'tax_document_available'
    )
  );

  const currentDate = new Date();
  const lastYear = currentDate.getFullYear() - 1;

  const hasDocs = accounts?.length > 0;
  const allDocs = accounts?.flatMap(account => account.offering_tax_statuses);
  const hasAvailableDoc = allDocs.some(
    doc => doc.step === 'tax_document_available'
  );
  const hasDelayedDoc = allDocs.some(
    doc =>
      (doc.estimate_status === 'estimate_anticipated' ||
        doc.estimate_status === 'no_estimate_anticipated') &&
      doc.step !== 'tax_document_available'
  );

  const activeAccount =
    accounts?.find(
      account => account.reference_id.toString() === accountParam
    ) || accounts[0];

  useEffect(() => {
    document.title = 'Tax Tracker | EquityMultiple';
    dispatch(loadTaxOfferingStatuses()).catch(error =>
      handleErrorResponse(navigate, error)
    );
  }, [dispatch, navigate]);

  useEffect(() => {
    if (accounts.length > 0) {
      handleTabClick(accountParam || accounts[0].reference_id.toString());
    }
  }, [accounts]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabClick = referenceId => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('account', referenceId);

    navigate(`?${newSearchParams.toString()}`, { replace: true });
  };

  const getAccountOptions = () => {
    return accounts.map(account => ({
      label: `${account.name} ${getAccountDescription(account)}`,
      value: account.id
    }));
  };

  const onSubmit = values => {
    dispatch(
      downloadTaxDocuments({
        investment_accounts: values.accountIds
      })
    )
      .then(blob => {
        utils.downloadFile(blob, `${lastYear}-tax-documents.zip`);

        handleCloseModal();
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    reset();
  };

  return (
    <Container>
      <Modal
        closeButtonText="Cancel"
        onClose={handleCloseModal}
        open={modalOpen}
        submitButton={
          <Button
            className={styles.modalSubmitButton}
            data-testid="submitModal"
            loading={downloading}
            onClick={handleSubmit(onSubmit)}
          >
            Download
          </Button>
        }
        title={<h6>Download Tax Documents</h6>}
      >
        <form>
          <p>
            Select investment accounts to download tax documents. Make sure you
            have all required information before filing your taxes.
          </p>

          <Controller
            control={control}
            name="accountIds"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Investment Account"
                multi
                options={getAccountOptions()}
                showMultiLabelsBelow
              />
            )}
          />
          {hasSelectedAccountIncompleteDocument && (
            <Alert className="margin-top-xx" type="warning">
              Please be advised that only a partial set of the documents are
              available for download at this time. You will be notified when all
              tax documents are available.
            </Alert>
          )}
          <FormError errors={errors} />
        </form>
      </Modal>
      <h1 className="margin-top-0" data-testid="taxTrackerHeading">
        Tax Tracker
      </h1>
      <Card className={styles.card}>
        {hasDocs || loading ? (
          <>
            <div className={styles.top}>
              <div className={styles.titleWrap}>
                <div>
                  <h6 className="margin-0">
                    {loading ? <Skeleton /> : `${lastYear} tax documents`}
                  </h6>
                  <div className={styles.subheading}>
                    {loading ? (
                      <Skeleton width={140} />
                    ) : (
                      `Displaying ${activeAccount.offering_tax_statuses.length}`
                    )}
                  </div>
                </div>
                {hasAvailableDoc && (
                  <Button
                    disabled={loading}
                    onClick={() => setModalOpen(true)}
                    size="small"
                  >
                    Download
                  </Button>
                )}
              </div>
              {hasDelayedDoc && (
                <Alert className="margin-top-x" type="warning">
                  Due to delayed tax documents, please consider filing for an
                  extension.
                </Alert>
              )}
              <hr className="cardHr" />

              <Tabs
                data-testid="accountsTabs"
                onChange={(_, newTabIndex) => handleTabClick(newTabIndex)}
                value={accountParam}
              >
                <TabsList collapseBreakpoint="md">
                  {loading ? <LoadingTabs /> : getAccountTabs(accounts)}
                </TabsList>
                {accounts?.map((account, index) => (
                  <TabPanel key={account.reference_id} value={index}>
                    {account.name}
                  </TabPanel>
                ))}
              </Tabs>
            </div>

            <TaxDocumentTable
              loading={loading}
              statuses={activeAccount?.offering_tax_statuses}
            />
          </>
        ) : (
          <NoDocuments startOfSeason={startOfSeason} />
        )}
      </Card>
      <h6>Additional Resources</h6>
      <div className={styles.resourceCards}>
        <ResourceCard
          data-testid="resourceCard"
          label="Resource"
          large
          title={
            <>
              What to Expect: Taxes at
              <br /> EquityMultiple
            </>
          }
          url="https://equitymultiple.com/blog/what-to-expect-taxes-at-equitymultiple"
        />
        <ResourceCard
          data-testid="resourceCard"
          label="Resource"
          large
          title={
            <>
              FAQs: K-1 Delivery and EquityMultiple
              <br /> Tax Practices
            </>
          }
          url="https://www.equitymultiple.com/blog/taxes/tax-practices"
        />
        <ResourceCard
          data-testid="resourceCard"
          label="File"
          large
          title={
            <>
              Common Tax Questions
              <br /> Tear Sheet
            </>
          }
          url="https://www.equitymultiple.com/blog/taxes/real-estate-taxes"
        />
      </div>
    </Container>
  );
};

function mapStateToProps(store) {
  return {
    accounts: store.offeringTaxStatuses.offeringTaxStatusInvestmentAccounts,
    downloading: store.offeringTaxStatuses.downloadingDocuments,
    loading: store.offeringTaxStatuses.loadingOfferingTaxStatuses,
    startOfSeason: store.offeringTaxStatuses.startOfSeason
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(TaxTracker);
