import Puzzle from 'images/puzzle.svg';
import React from 'react';

import * as styles from './NoDocuments.module.scss';

interface Props {
  startOfSeason: string;
}

const NoDocuments = ({ startOfSeason }: Props) => {
  const currentDate = new Date();
  const lastYear = currentDate.getFullYear() - 1;
  const isInTaxSeason = startOfSeason && currentDate > new Date(startOfSeason);

  return (
    <div className={styles.wrap}>
      <Puzzle />
      <h6>No tax documents</h6>
      <p>
        {isInTaxSeason
          ? 'There are no tax documents expected for your investment account(s).'
          : `Your ${lastYear} tax documents will appear here once we start initial data preparation.`}
      </p>
    </div>
  );
};

export default NoDocuments;
