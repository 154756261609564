import { Button, StatusLabel, Table } from '@equitymultiple/react-eui';
import LinkIcon from 'images/icons/link.svg';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { OfferingTaxStatus } from 'types/actions/offering-tax-stauses';

import { tableColumnLabels } from '../constants';
import { getTaxDocStepInfo } from '../helpers';
import TaxDocStep from './TaxDocStep/TaxDocStep';
import * as styles from './TaxDocumentTable.module.scss';

const getDocStatus = step => {
  let text = 'Processing';
  let color = '#fdb309';

  if (step === 'initial_data_preparation') {
    text = 'Not Started';
    color = '#7f8083';
  } else if (step === 'tax_document_available') {
    text = 'Available';
    color = '#4caf50';
  }

  return <StatusLabel color={color} label={text} />;
};

const getEstimatedCompletionDate = status => {
  const estimatedCompletionDate = status.estimated_completion_date;
  let date = '--';

  if (estimatedCompletionDate && status.status !== 'Available')
    date = moment(estimatedCompletionDate).format('MMM D, YYYY');

  return <span data-testid="estimatedCompletionDate">{date}</span>;
};

interface Props {
  loading: boolean;
  statuses: OfferingTaxStatus[];
}

const getRows = (statuses: OfferingTaxStatus[]) => {
  const rows = statuses.map(status => {
    const isStn = status.offering.is_stn;
    const offeringTitle = isStn ? 'Alpine Notes' : status.offering.title;
    const isAvailable =
      status.step === 'tax_document_available' && status.document?.url;

    return {
      cells: [
        {
          sortableValue: offeringTitle,
          value: (
            <div key="offering">
              <div className={styles.offeringName}>
                {offeringTitle}
                <Link target="_blank" to={`/invest/${status.offering.id}`}>
                  <LinkIcon />
                </Link>
              </div>
              <div className={styles.entityName}>
                {status.offering.entity_name}
              </div>
            </div>
          )
        },
        status.document_type,
        {
          sortableValue: getTaxDocStepInfo(status.step),
          value: getDocStatus(status.step)
        },
        {
          sortableValue: getTaxDocStepInfo(status.step),
          value: <TaxDocStep status={status} />
        },
        getEstimatedCompletionDate(status),
        <Button
          disabled={!isAvailable}
          key="download"
          label="download"
          leadingIcon="download"
          size="small"
          variant="outlined"
          wrapper={
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              download
              href={status.document?.url}
              rel="noreferrer"
              target="_blank"
            />
          }
        />
      ]
    };
  });

  return rows;
};

const TaxDocumentTable = ({ loading, statuses }: Props) => {
  return (
    <Table
      allowSorting
      className={styles.table}
      columnHeaders={tableColumnLabels}
      data-testid="statusesTable"
      loading={loading}
      loadingRows={5}
      rows={loading ? [] : getRows(statuses)}
    />
  );
};

export default TaxDocumentTable;
