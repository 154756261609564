import { Button } from '@equitymultiple/react-eui';
import React from 'react';
import { UpdateAlert } from 'types/actions/user-update-alerts';

import { SubmittedUpdateAlert } from '../types';
import * as sharedStyles from '../UserUpdateAlertsShared.module.scss';

interface Props {
  alert: UpdateAlert;
  handleSubmitAlert: (alert: SubmittedUpdateAlert) => void;
}

const PrivacyPolicyTos = ({ alert, handleSubmitAlert }: Props) => {
  const handleSubmit = () => {
    handleSubmitAlert({
      ...alert,
      action: 'Acknowledged 2020 privacy updates'
    });
  };

  return (
    <>
      <h4 className="margin-xx">Terms of Service and Privacy Updates</h4>
      <p className="margin-x size-16 text-medium-gray">
        At EquityMultiple, our investors are the focus of everything we do. For
        this reason, it&apos;s very important to us that you are in control of
        the personal information you share when investing with us. Effective
        February 6, 2020, we&apos;ve updated our{' '}
        <a href="https://www.equitymultiple.com/terms/">Terms of Service</a> and{' '}
        <a href="https://www.equitymultiple.com/privacy/">Privacy Policy</a>. In
        short, here is what has changed:{' '}
      </p>
      <ul className="margin-x size-16 text-medium-gray">
        <li>
          We’ve added explanations in our Privacy Policy to increase
          transparency about the types of information we collect from users and
          how we use, share and retain such information.
        </li>
        <li>
          If you are a California resident, we’ve included additional privacy
          rights as provided under the California Consumer Privacy Act (CCPA).
        </li>
      </ul>
      <p className="margin-xx size-16 text-medium-gray">
        This summary does not cover every change and we therefore encourage you
        to review and acknowledge the full, updated versions. Once you have had
        the chance to review, please acknowledge below.
      </p>
      <div className={sharedStyles.continueButtonWrap}>
        <Button onClick={handleSubmit} style={{ width: 240 }}>
          Acknowledge
        </Button>
      </div>
    </>
  );
};

export default PrivacyPolicyTos;
