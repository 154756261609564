import {
  DownloadTaxDocumentsPayload,
  LoadOfferingTaxStatusesResponse
} from 'types/actions/offering-tax-stauses';
import { AsyncActionCreator } from 'types/redux';

export const FETCH_TAX_OFFERING_STATUSES =
  'taxOfferingStatuses/FETCH_TAX_OFFERING_STATUSES';
export const FETCH_TAX_OFFERING_STATUSES_SUCCESS =
  'taxOfferingStatuses/FETCH_TAX_OFFERING_STATUSES_SUCCESS';
export const FETCH_TAX_OFFERING_STATUSES_FAIL =
  'taxOfferingStatuses/FETCH_TAX_OFFERING_STATUSES_FAIL';

export const DOWNLOAD_DOCUMENTS = 'taxOfferingStatuses/DOWNLOAD_DOCUMENTS';
export const DOWNLOAD_DOCUMENTS_SUCCESS =
  'taxOfferingStatuses/DOWNLOAD_DOCUMENTS_SUCCESS';
export const DOWNLOAD_DOCUMENTS_FAIL =
  'taxOfferingStatuses/DOWNLOAD_DOCUMENTS_FAIL';

export function loadTaxOfferingStatuses(): AsyncActionCreator<LoadOfferingTaxStatusesResponse> {
  return {
    promise: client =>
      client.get<LoadOfferingTaxStatusesResponse>('/mkt/offering_tax_statuses'),
    types: [
      FETCH_TAX_OFFERING_STATUSES,
      FETCH_TAX_OFFERING_STATUSES_SUCCESS,
      FETCH_TAX_OFFERING_STATUSES_FAIL
    ]
  };
}

export function downloadTaxDocuments(params: DownloadTaxDocumentsPayload) {
  return {
    promise: client =>
      client.post('/mkt/offering_tax_statuses/download', {
        data: params,
        isBlob: true
      }),
    types: [
      DOWNLOAD_DOCUMENTS,
      DOWNLOAD_DOCUMENTS_SUCCESS,
      DOWNLOAD_DOCUMENTS_FAIL
    ]
  };
}
