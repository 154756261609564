import { Tooltip } from '@equitymultiple/react-eui';
import { getTaxDocStepInfo, taxDocSteps } from 'containers/TaxTracker/helpers';
import React from 'react';
import { OfferingTaxStatus } from 'types/actions/offering-tax-stauses';

import * as styles from './TaxDocStep.module.scss';

interface Props {
  status: OfferingTaxStatus;
}

const TaxDocStep = ({ status }: Props) => {
  const isStn = status.offering.is_stn;
  const stepCount = isStn ? 2 : 5;
  const currentStep = getTaxDocStepInfo(status.step) as number;
  const message = getTaxDocStepInfo(status.step, 'message');

  return (
    <div>
      <div>{message}</div>
      <div className={styles.steps}>
        {Array.from({ length: stepCount }, (_, index) => {
          const step = index + 1;
          let className = `${styles.step}`;
          if (currentStep === step && step !== stepCount)
            className += ` ${styles.current}`;
          else if (currentStep >= step) className += ` ${styles.complete}`;

          let tooltipMessage = getTaxDocStepInfo(taxDocSteps[index], 'message');
          if (isStn && step === 2)
            tooltipMessage = getTaxDocStepInfo(
              'tax_document_available',
              'message'
            );

          return (
            <Tooltip
              className={className}
              key={index}
              tooltipContent={tooltipMessage}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TaxDocStep;
