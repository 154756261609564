import { addressValidation } from 'containers/Profile/validation';
import { validations, yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields, tests } = validations;

export const profileSchema = yup.object().shape({
  address_attributes: addressValidation,
  country_code: fields.countryCode,
  email: yupString.notRequired(),
  first_name: fields.firstName,
  id_document: yup.object().shape({
    attachment: fields.idDocument,
    expiry: fields.idExpiration,
    type: fields.idType
  }),
  investor_profile_attributes: yup.object().shape({
    accreditation: fields.accreditation,
    country_of_citizenship: fields.countryOfCitizenship,
    dob: fields.dob.test(tests.ageOver18),
    investment_experience: fields.investmentExperience,
    investment_objectives: fields.investmentObjectives,
    investment_reason: fields.investmentReason,
    occupation: fields.occupation,
    passport: yupString
      .notRequired()
      .test(
        'Required when ID type is passport',
        'Passport number is required',
        function (value) {
          const idType = this.options.context.formValues.id_document.type;

          return !!value || idType !== 'passport';
        }
      ),
    residence_status: fields.residenceStatus,
    risk_tolerance: fields.riskTolerance,
    ssn: fields.ssn
  }),
  last_name: fields.lastName,
  phone: fields.phone
});

export const updateEmailSchema = yup.object().shape({
  unconfirmed_email: yupString
    .email('Must be a valid email address')
    .required('New email address is required')
});

export const updatePasswordSchema = yup.object().shape({
  confirmPassword: fields.confirmPassword,
  current_password: yupString.required('Current password is required'),
  password: fields.password
});

export const phoneSchema = yup.object().shape({
  country_code: yupString.required('Country code is required'),
  phone: fields.phone
});
