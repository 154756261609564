import {
  Button,
  Card,
  EMLoadingIcon,
  FileIcon,
  StatusLabel,
  Tooltip
} from '@equitymultiple/react-eui';
import Contact from 'components/Contact/Contact';
import ProgressBarBorder from 'components/ProgressBarBorder/ProgressBarBorder';
import RichText from 'components/RichText';
import useRedirectUserWIthInterestAscentInvestment from 'hooks/useRedirectUserWIthInterestAscentInvestment';
import sumBy from 'lodash/sumBy';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { loadFundingOptions, loadInvestment } from 'redux/actions/investments';
import { loadClosing } from 'redux/actions/offerings';
import {
  FundingOptions,
  LoadInvestmentResponse
} from 'types/actions/investments';
import { LoadClosingResponse } from 'types/actions/offerings';
import { Dispatch } from 'types/redux';
import { adBlockerIntercomTooltip } from 'utilities/constants';
import { handleErrorResponse } from 'utilities/errorHandlers';
import utils from 'utilities/utils';

import AccreditationCard from '../../components/AccreditationCard/AccreditationCard';
import InvestmentStatus from '../../components/InvestmentStatus/InvestmentStatus';
import InvestmentTitle from '../../components/InvestmentTitle/InvestmentTitle';
import {
  amountInvested,
  getTransactionStatusColor,
  hasPendingRolloverTransaction,
  redirectIfInvestmentOnWaitlist
} from '../../helpers';
import * as styles from './Review.module.scss';

type Params = {
  closing_id: string;
  investment_id: string;
  offering_id: string;
};

interface Props {
  closing: LoadClosingResponse['closing'];
  dispatch: Dispatch;
  fundingOptions: FundingOptions;
  investment: LoadInvestmentResponse;
  loading: boolean;
  offering: LoadClosingResponse['offering'];
}

const renderTransactions = (transactions, originationFees) => {
  return transactions
    ? transactions.map(transaction => (
        <Row
          className={`margin-x ${styles.transactionRow}`}
          key={transaction.id}
        >
          <Col md={6}>
            <div className={`text-label ${styles.rowlabel}`}>
              Funding Method
            </div>
            {transaction.payment_method === 'REDEMPTION' ? (
              <div>Alpine Note Principal Balance</div>
            ) : (
              transaction.payment_method.toUpperCase()
            )}
          </Col>
          <Col md={3}>
            <div className={`text-label ${styles.rowlabel}`}>
              Funding Amount
            </div>
            {utils.formatCurrency(amountInvested(transaction, originationFees))}
            {transaction.type === 'Capital Call' && (
              <span> (Capital Call)</span>
            )}
          </Col>
          <Col className={`text-right ${styles.statusLabel}`} md={3}>
            <div className={`text-label ${styles.rowlabel}`}>Status</div>
            <StatusLabel
              color={getTransactionStatusColor(transaction.status)}
              label={utils.titalize(transaction.status)}
            />
          </Col>
        </Row>
      ))
    : null;
};

const Review = ({
  closing,
  dispatch,
  fundingOptions,
  investment,
  loading,
  offering
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  useRedirectUserWIthInterestAscentInvestment(dispatch, offering);

  const [capitalCall, setCapitalCall] = useState(false);

  useEffect(() => {
    const newInvestmentRoute = `/invest/${params.closing_id}/investment/new`;

    document.title = 'Investment Summary | EquityMultiple';

    if (!params.closing_id) {
      navigate('/');
    } else if (!params.investment_id) {
      navigate(newInvestmentRoute);
    } else {
      dispatch(loadClosing(params.closing_id)).catch(error =>
        handleErrorResponse(navigate, error)
      );
      dispatch(loadInvestment(params.investment_id)).then(investmentRes => {
        redirectIfInvestmentOnWaitlist(
          navigate,
          investmentRes,
          params.closing_id
        );
        dispatch(loadFundingOptions(params.investment_id));

        if (investmentRes.capital_call_opt_in) {
          // Send back to fund if they've opted in to capital call but transaction is still in draft
          if (
            investmentRes.transactions?.contributions?.some(
              transaction =>
                transaction.status === 'draft' &&
                transaction.type === 'Capital Call'
            )
          )
            navigate(
              `/invest/${params.closing_id}/investment/${params.investment_id}/fund`
            );
          else setCapitalCall(true);
        }
      });
    }
  }, [dispatch, params.closing_id, params.investment_id, navigate]);

  let ccTransaction = null;
  let transactionsWithInstructions = [];
  let lastPendingWireOrCheckTransaction = null;
  let pendingWireOrCheckIsCapitalCall = false;
  let showFundingChangeCard = false;
  let effectiveDate = '';
  if (investment?.transactions?.contributions) {
    const pendingWireOrCheckTransactions =
      investment.transactions.contributions.filter(
        transaction =>
          (transaction.payment_method === 'CHECK' ||
            transaction.payment_method === 'WIRE') &&
          transaction.status !== 'posted'
      );

    lastPendingWireOrCheckTransaction =
      pendingWireOrCheckTransactions[pendingWireOrCheckTransactions.length - 1];

    pendingWireOrCheckIsCapitalCall =
      lastPendingWireOrCheckTransaction?.type === 'Capital Call';

    const hasLinkedBankAccount = fundingOptions?.funding_sources?.some(
      bankAccount => bankAccount.status === 'verified'
    );

    if (lastPendingWireOrCheckTransaction && hasLinkedBankAccount)
      showFundingChangeCard = true;

    investment.transactions.contributions.forEach(transaction => {
      if (
        transaction.type === 'Capital Call' &&
        transaction.status === 'pending'
      )
        ccTransaction = transaction;
    });
    if (ccTransaction) {
      transactionsWithInstructions.push(ccTransaction);
      effectiveDate = utils.dateFormat(ccTransaction.effective_date);
    } else transactionsWithInstructions = investment.transactions.contributions;
  }

  let currentStep = 4;
  let showAccreditationCard = true;
  if (investment?.investment_account?.accreditation_status === 'verified') {
    currentStep = 5;
    showAccreditationCard = false;
  }

  const showInvestMore =
    investment &&
    !hasPendingRolloverTransaction(investment.transactions?.contributions) &&
    closing.stage === 'active' &&
    closing.max_investment_amount !== 0 &&
    parseFloat(investment.amount) < Number(closing.max_investment_amount);

  const showLoading = !investment || !offering || !closing || loading;

  const offeringReference = offering.stn
    ? offering.title
    : offering.entity_name;

  return showLoading ? (
    <EMLoadingIcon />
  ) : (
    <div>
      <Container className="container-narrow clearfix-after">
        <InvestmentTitle title={offering.title} />
        <InvestmentStatus
          capitalCallTransaction={capitalCall ? ccTransaction : null}
          closing={closing}
          dateInvested={closing?.date_invested}
          investment={investment}
          noTimer
          step={4}
        />

        <div className={styles.cardWrap}>
          {showAccreditationCard && (
            <AccreditationCard
              accountName={investment.investment_account.entity_name}
              accountReferenceId={investment.investment_account.reference_id}
              accreditationStatus={
                investment.investment_account.accreditation_status
              }
            />
          )}

          {showFundingChangeCard && (
            <Card className="card no-clearfix">
              <h4 className="margin-top-0">
                Want to Fund Sooner? Complete Your Payment with ACH
              </h4>
              <p>
                Funding by checks can take 3-10 business days to process. With
                ACH, we can securely process your payment in just 2-3 business
                days.
              </p>
              <Link
                className="text-link"
                to={
                  pendingWireOrCheckIsCapitalCall
                    ? `/invest/${closing.id}/investment/${investment.id}/capital_call/${lastPendingWireOrCheckTransaction.id}/fund`
                    : `/invest/${closing.id}/investment/${investment.id}/fund`
                }
              >
                Click Here to Fund by ACH
              </Link>
            </Card>
          )}

          <Card className={!showAccreditationCard ? 'border-top-card' : ''}>
            {!showAccreditationCard && (
              <ProgressBarBorder currentStep={currentStep} steps={4} />
            )}
            {capitalCall && (
              <h5>
                We appreciate your involvement in this offering and your
                participation in the capital call. We will continue to provide
                you with updates as your funds go to work and the project moves
                forward.
              </h5>
            )}
            <h4>Investment Summary</h4>
            <p className="margin-xx">
              You can track the status of your investment here. Once each step,
              including funding, is marked complete all you have to do is wait
              for the investment to close.
            </p>

            <div className="margin-xx">
              <Row>
                <Col md={6}>
                  <div className="text-label">Offering Name</div>
                  <p>{offering.title}</p>
                </Col>
                <Col md={6}>
                  <div className="text-label">
                    {capitalCall
                      ? 'Capital Call Close Date'
                      : 'Anticipated Closing Date'}
                  </div>
                  <p>
                    {capitalCall
                      ? effectiveDate
                      : utils.dateFormat(closing.funding_end_date)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="text-label">Entity Name</div>
                  <p>{offering.entity_name.toUpperCase() || 'N/A'}</p>
                </Col>
                <Col md={6}>
                  <div className="text-label">Investment Amount</div>
                  <p>{utils.formatCurrency(investment.amount)}</p>
                </Col>
              </Row>
              <Row>
                {!offering.perpetual_fund && (
                  <Col md={6}>
                    <div className="text-label">Units</div>
                    <p>{investment.equity_share_count}</p>
                  </Col>
                )}
                <Col md={6}>
                  <div className="text-label">Investment Account</div>
                  <p>{`${investment.investment_account.entity_name} (${utils.titalize(
                    investment.investment_account.type
                  )})`}</p>
                </Col>
              </Row>
              {!offering.custom_instructions_enabled &&
                investment.transactions && (
                  <div>
                    <h5>Transactions</h5>
                    <div className={styles.transactionRowHeader}>
                      <Col className="text-label" md={6}>
                        Funding Method
                      </Col>
                      <Col className="text-label" md={3}>
                        Funding Amount
                      </Col>
                      <Col className="text-label text-right" md={3}>
                        Status
                      </Col>
                    </div>
                    {renderTransactions(
                      investment.transactions.contributions,
                      investment.transactions.origination_fees
                    )}
                    {renderTransactions(
                      investment.transactions.failed,
                      investment.transactions.origination_fees
                    )}
                  </div>
                )}
              {!offering.custom_instructions_enabled &&
                investment.transactions?.refunds?.length > 0 && (
                  <div>
                    <h5>Refunds</h5>
                    {renderTransactions(
                      investment.transactions.refunds,
                      investment.transactions.origination_fees
                    )}
                  </div>
                )}
              {showInvestMore && (
                <Button
                  variant="orange"
                  wrapper={
                    <Link
                      to={`/invest/${closing.id}/investment/${investment.id}/edit`}
                    />
                  }
                >
                  Invest More
                </Button>
              )}
            </div>

            <div className="margin-xx">
              <h5>Documents</h5>
              {investment.subscription_agreement && (
                <div
                  className={styles.subscriptionAgreementWrap}
                  data-testid="subscriptionAgreementSection"
                >
                  <FileIcon className={styles.docIcon} url="agreement.pdf" />
                  {!investment.subscription_agreement.meta.is_complete && (
                    <strong className={styles.pendingDoc}>
                      Pending Countersignature -
                    </strong>
                  )}
                  {investment.subscription_agreement.attachment.url ? (
                    <a
                      href={investment.subscription_agreement.attachment.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {offering.title} Subscription Agreement
                    </a>
                  ) : (
                    <span>{offering.title} Subscription Agreement</span>
                  )}
                </div>
              )}
            </div>

            {offering.custom_instructions_enabled && (
              <>
                <h5>Funding Instructions</h5>
                <p>
                  <RichText
                    sanitize
                    text={offering.custom_funding_instructions}
                  />
                </p>
              </>
            )}

            <h4>Next Steps</h4>

            {offering.custom_instructions_enabled ? (
              <p>
                <RichText sanitize text={offering.custom_funding_next_steps} />
              </p>
            ) : (
              <div>
                {transactionsWithInstructions?.filter(
                  transaction =>
                    transaction.payment_method === 'WIRE' &&
                    transaction.status === 'pending'
                ).length > 0 && (
                  <div data-testid="wire-instructions">
                    <p>
                      You have indicated that you&apos;ll fund{' '}
                      {capitalCall ? 'the capital call' : 'your investment'}{' '}
                      into {offering.title} by wire. To fund, please work with
                      your bank to send a wire in the amount of
                      <strong>
                        {' '}
                        {utils.formatCurrency(
                          sumBy(
                            investment.transactions.contributions.filter(
                              transaction =>
                                transaction.payment_method === 'WIRE' &&
                                transaction.status === 'pending'
                            ),
                            wire => parseFloat(wire.amount)
                          )
                        )}
                      </strong>
                      .
                    </p>
                    <p>
                      Please note, wire transfers must be sent from a bank
                      account linked to{' '}
                      <strong>
                        {investment.investment_account.entity_name}
                      </strong>
                      . Please reference <strong>{offeringReference}</strong> on
                      the wire transfer.
                    </p>
                    <div className="text-label">Wire to</div>
                    <p>
                      Bank Name:
                      <strong> {offering.wire_info.bank_name}</strong>
                      <br />
                      Bank Address:
                      <strong> {offering.wire_info.bank_address}</strong>
                      <br />
                      Account Name:
                      <strong> {offering.wire_info.account_name}</strong>
                      <br />
                      Account Number:
                      <strong> {offering.wire_info.account}</strong>
                      <br />
                      Routing Number:
                      <strong> {offering.wire_info.wire_routing}</strong>
                      <br />
                      Entity Address:
                      <strong> 91 5th Ave. Floor 5 New York, NY 10003</strong>
                    </p>
                  </div>
                )}

                {transactionsWithInstructions?.filter(
                  transaction =>
                    transaction.payment_method === 'CHECK' &&
                    transaction.status === 'pending'
                ).length > 0 && (
                  <div data-testid="check-instructions">
                    {capitalCall && (
                      <div>
                        <h5>Check Disclaimer</h5>
                        <p>
                          To ensure your participation is secure in the
                          aforementioned Capital Call, we kindly ask that you
                          plan accordingly to have your check arrive by{' '}
                          {effectiveDate}. We cannot guarantee that we will be
                          able to accept funds if received after this date. If
                          you prefer to fund through another method (ACH or
                          wire), please contact a member of our investor
                          relations team to reset your payment method.
                        </p>
                      </div>
                    )}
                    <p>
                      You have indicated that you&apos;ll fund{' '}
                      {capitalCall ? 'the capital call' : 'your investment'}{' '}
                      into {offering.title} by check. To fund, make the check
                      out to
                      <strong>
                        {' '}
                        {offering.check_info.payee_name.toUpperCase()}
                      </strong>{' '}
                      in amount of{' '}
                      <strong>
                        {' '}
                        {utils.formatCurrency(
                          sumBy(
                            investment.transactions.contributions.filter(
                              transaction =>
                                transaction.payment_method === 'CHECK' &&
                                transaction.status === 'pending'
                            ),
                            check => parseFloat(check.amount)
                          )
                        )}
                      </strong>
                      .
                    </p>
                    <p>
                      Please note, check transfers must be sent from a bank
                      account linked to{' '}
                      <strong>
                        {investment.investment_account.entity_name}
                      </strong>
                      . Please reference <strong>{offeringReference}</strong> in
                      the memo section on the check.
                    </p>
                    <div className="text-label">Mail to</div>
                    {offering.check_info.payee_name}
                    <br />
                    {(offering.check_info.address || '')
                      .split('\n')
                      .map(address => (
                        <Fragment key={address}>
                          <span>{address}</span>
                          <br />
                        </Fragment>
                      ))}
                    <br />
                  </div>
                )}

                <p>
                  We are actively working to close this investment, and will
                  notify you when the investment closes via{' '}
                  <Link to="/activity">My Activity</Link> page.
                </p>
              </div>
            )}

            <p>
              Please contact us if you have any questions. You can reach us by
              phone at{' '}
              <a className="underline" href="tel:16468449918">
                (646) 844-9918
              </a>
              , email at{' '}
              <a className="underline" href="mailto:help@equitymultiple.com">
                help@equitymultiple.com
              </a>
              , or by clicking the chat icon{' '}
              <Tooltip infoIcon tooltipContent={adBlockerIntercomTooltip} /> in
              the bottom right of this page to start a live conversation.
            </p>
          </Card>

          {offering.accepts_credits && !capitalCall && (
            <Card>
              <h4>Want even better returns for you and your friends?</h4>
              <h5>Invite Them</h5>
              <p>
                When you invite your friends and they invest, you both earn
                credit toward improved return. Click{' '}
                <Link className="underline" to="/users/invitation/new">
                  here
                </Link>{' '}
                to start inviting
              </p>
            </Card>
          )}
          <Contact />
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    closing: state.offerings.closing,
    fundingOptions: state.investments.fundingOptions,
    investment: state.investments.investment,
    loading: state.investments.loading,
    offering: state.offerings.offering
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Review);
