import { Alert, Button, StatusLabel, Tooltip } from '@equitymultiple/react-eui';
import { ButtonProps } from '@equitymultiple/react-eui/dist/components/Button/Button';
import IconEarn from 'images/icons/earn.svg';
import IconGrow from 'images/icons/grow.svg';
import IconKeep from 'images/icons/keep.svg';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { User } from 'types/actions/auth';
import { LoadOfferingsOffering } from 'types/actions/invest';
import { segmentTrackData } from 'utilities/analyticsHelpers';
import EmAnalytics from 'utilities/em_analytics';
import { trackInvestorPacketClick } from 'utilities/trackEvents';
import utils from 'utilities/utils';

import { getInvestorPacketUrlWithPage } from '../../../Offerings/helpers';
import {
  formatIrrForOfferingCard,
  getTargetReturnValue,
  isAnnualizedYield
} from '../../helpers';
import { getInvestmentWizardRoute, getOfferingCardValue } from './helpers';
import * as styles from './OfferingCard.module.scss';
import OfferingCardTimer from './OfferingCardTimer/OfferingCardTimer';

interface Props {
  cardIndex?: number;
  currentUser?: User;
  isHighlighted?: boolean;
  loading?: boolean;
  offering?: LoadOfferingsOffering;
}

const LoadingCard = () => (
  <div className={`${styles.offeringCard} ${styles.cardDisabled}`}>
    <div className={styles.cardTop}>
      <div className={styles.pillar}>
        <div className={`${styles.pillarIcon} ${styles.loading}`} />
        <span />
      </div>
      <div className={styles.loadingTimer} />
    </div>
    <div className={styles.cardImage} />
    <div className={styles.cardContent}>
      <div className={styles.location}>
        <Skeleton width="40%" />
      </div>
      <h4 className={styles.offeringTitle}>
        <Skeleton />
      </h4>
      <div>
        <div className={styles.metric}>
          <div className={styles.metricLabel}>Investment Type</div>
          <div className={styles.metricValue}>
            <Skeleton width={100} />
          </div>
        </div>
        <div className={styles.metric}>
          <div className={styles.metricLabel}>Rate</div>
          <div className={styles.metricValue}>
            <Skeleton width={100} />
          </div>
        </div>
        <div className={styles.metric}>
          <div className={styles.metricLabel}>Investment Minimum</div>
          <div className={styles.metricValue}>
            <Skeleton width={100} />
          </div>
        </div>
      </div>
    </div>
    <div className={styles.buttonWrap}>
      <Skeleton className={styles.loadingButton} />
    </div>
  </div>
);

const OfferingCard = ({
  cardIndex,
  currentUser,
  isHighlighted,
  loading,
  offering
}: Props) => {
  const offeringId = offering?.id;
  const closing = offering?.closing;
  const investment = offering?.investment;
  const disabled = offering?.disabled;

  const cardValue = getOfferingCardValue(closing?.stage, investment);
  let cardButtonVariant = 'blue';
  if (
    cardValue === 'Complete Your Investment' ||
    cardValue === 'Update Payment'
  ) {
    cardButtonVariant = 'orange';
  }

  const trackClick = () => {
    const trackData = {
      ...segmentTrackData(offering, currentUser),
      action: cardValue,
      cardPosition: cardIndex,
      offeringCardType: isHighlighted ? 'highlight' : 'standard'
    };

    EmAnalytics.track('Clicked Offering Card', 'Navigation', trackData);
  };

  const getButtonLink = () => {
    if (investment) {
      return getInvestmentWizardRoute(closing.id, offering.investment);
    }

    return `/invest/${offeringId}/closings/${closing.id}`;
  };

  const renderOfferingCardBody = () => {
    const approach = offering.offering_approach;
    const showProgress = offering.show_progress_bar;
    const investmentMinimumEnabled = offering.investment_minimum_enabled;
    const hasInvestment = cardValue === 'Invest More/View Summary';
    const showAlert = investmentMinimumEnabled || hasInvestment;
    let investorPacketUrl = getInvestorPacketUrlWithPage(
      offering.investor_packet_url,
      offeringId
    );
    const showInvestorPacket =
      offering.show_investor_packet && investorPacketUrl;

    const strategy = offering.strategy;
    const strategies = strategy?.split(',');
    const isEquity = strategies.includes('Equity');
    const isDebt = strategies.includes('Debt');
    const isSTN = strategies.includes('Short Term Note');
    const isPrefEquity = strategies.includes('Preferred Equity');
    const strategyValue = strategy.replace(',', ', ');
    const isFund = approach === 'Fund';
    const isNetherwood = offeringId === 473;
    const isTrevian = offeringId === 465;
    const isStarwood = offeringId === 439;
    const progressBarWidth = Math.min(offering.percent_commitment_funded, 100);

    if (isStarwood)
      investorPacketUrl = 'http://connect.rightprospectus.com/Starwood/SPCD/';

    let returnMetricLabel = 'Target Return';
    if (isEquity && !isPrefEquity) {
      returnMetricLabel = 'Target Investor IRR';
    } else if (isFund) {
      if (showInvestorPacket) {
        if (isPrefEquity) returnMetricLabel = 'Total Preferred Return';
        else if (isDebt) returnMetricLabel = 'Debt';
      }
    } else if (isPrefEquity) {
      returnMetricLabel = 'Total Preferred Return';
    } else if (isSTN) {
      returnMetricLabel = isAnnualizedYield(offering) ? 'APY' : 'Rate';
    } else if (isDebt) {
      returnMetricLabel = 'Rate';
    }

    if (isNetherwood) returnMetricLabel = 'Proforma Total Returns';

    const returnMetricValue = getTargetReturnValue(offering);

    const showInvestorPacketLink =
      isNetherwood ||
      isTrevian ||
      ((isPrefEquity || isDebt) && isFund && showInvestorPacket);

    let formattedReturnMetric;

    if (showInvestorPacketLink) {
      formattedReturnMetric = (
        <a
          className={`text-link ${styles.investorPacketLink}`}
          href={investorPacketUrl}
          onClick={() =>
            trackInvestorPacketClick(
              'offering card',
              offering.title,
              currentUser
            )
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          See Investor Packet
        </a>
      );
    } else {
      if (isEquity)
        formattedReturnMetric = formatIrrForOfferingCard(returnMetricValue);
      else {
        formattedReturnMetric = `${returnMetricValue}%`;
      }
    }

    const progressLabelInsideBar = progressBarWidth > 60;

    const renderButton = () => (
      <div className={styles.buttonWrap}>
        <Button
          className={styles.button}
          variant={cardButtonVariant as ButtonProps['variant']}
          wrapper={<Link onClick={trackClick} to={getButtonLink()} />}
        >
          {cardValue}
        </Button>
      </div>
    );

    return (
      <>
        <Link
          className={styles.cardLink}
          data-testid="cardLink"
          onClick={trackClick}
          to={`/invest/${offering.id}/closings/${closing.id}`}
        >
          <div className={styles.cardTop}>
            <div className={styles.pillar}>
              <div
                className={`${styles.pillarIcon} ${styles[offering.pillar]}`}
              >
                {offering.pillar === 'keep' && <IconKeep />}
                {offering.pillar === 'earn' && <IconEarn />}
                {offering.pillar === 'grow' && <IconGrow />}
              </div>
              <span>{utils.titalize(offering.pillar)}</span>
            </div>
            <OfferingCardTimer deadline={offering.closing.funding_end_date} />
          </div>

          <div className={styles.cardImageWrap}>
            <div
              className={styles.cardImage}
              style={{
                backgroundImage: `url(${offering.card_image})`
              }}
            >
              {showAlert && (
                <Alert
                  className={styles.cardAlert}
                  type={hasInvestment ? 'neutral' : 'positive'}
                >
                  {hasInvestment
                    ? 'You can update your investment amount before the closing date.'
                    : 'You can now invest a lower investment amount. The minimum has been fulfilled.'}
                </Alert>
              )}
              {offering.show_view_count && (
                <div className={styles.viewsCount}>
                  {`${offering.views} ${offering.views > 1 ? 'views' : 'view'}`}
                </div>
              )}
              <div className={styles.headerBottom}>
                {showProgress && (
                  <>
                    <div className={styles.progress}>
                      <div
                        className={styles.progressBar}
                        data-testid="progressBar"
                        style={{
                          width: `${progressBarWidth}%`
                        }}
                      />
                      <div
                        className={`${styles.progressLabel} ${progressLabelInsideBar ? styles.labelInside : ''}`}
                        style={{
                          right: `${100 - progressBarWidth}%`
                        }}
                      >
                        {offering.percent_commitment_funded}% Invested
                        {offering.percent_commitment_funded > 100 && (
                          <Tooltip
                            infoIcon
                            infoIconColor="#2a2b30"
                            tooltipContent={
                              <span>
                                <strong>
                                  Why is this offering over 100% subscribed?
                                </strong>{' '}
                                In short, this progress bar reflects indications
                                of interest from investors, and actual placement
                                into investments occurs on a “first fund - first
                                in” basis. Note that we will never accept more
                                than the target amount such that any
                                investor&apos;s position is diluted. Please
                                refer to{' '}
                                <a
                                  href="https://www.equitymultiple.com/blog/equitymultiple-operations-methods/navigate-equitymultiples-investment-statuses/"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  this article
                                </a>{' '}
                                for more details on our closing process.
                              </span>
                            }
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className={styles.cardContent}>
            <div className={styles.titleWrap}>
              <div>
                <div className={styles.location}>{offering.location}</div>
                <h4 className={styles.offeringTitle}>{offering.title}</h4>
              </div>
              {isHighlighted && (
                <div className={styles.featuredLabel}>
                  <StatusLabel color="#ef5b09" label="Featured" />
                </div>
              )}
            </div>

            <div className={styles.metrics}>
              <div className={styles.metric}>
                <div className={styles.metricLabel}>
                  {['Alternative to Savings', 'Direct', 'Fund'].includes(
                    approach
                  )
                    ? 'Investment Type'
                    : 'Tax Strategy'}
                </div>
                <div className={styles.metricValue}>{strategyValue}</div>
              </div>
              <>
                <div className={styles.metric}>
                  <div
                    className={styles.metricLabel}
                    data-testid="return-metric-label"
                  >
                    {returnMetricLabel}
                  </div>
                  <div
                    className={styles.metricValue}
                    data-testid="return-metric"
                  >
                    {formattedReturnMetric}
                  </div>
                </div>
                <div className={styles.metric}>
                  <div className={styles.metricLabel}>Investment Minimum</div>
                  <div className={styles.metricValue}>
                    {utils.formatCurrency(
                      offering.minimum_investment_amount,
                      0
                    )}
                  </div>
                </div>
              </>
            </div>
            {isHighlighted && offering.description && (
              <p className={styles.description}>{offering.description}</p>
            )}
            {!offering.disabled && isHighlighted && renderButton()}
          </div>
        </Link>

        {!offering.disabled && !isHighlighted && renderButton()}
      </>
    );
  };

  return loading ? (
    <LoadingCard />
  ) : (
    <div
      className={`${styles.offeringCard} ${
        disabled ? styles.cardDisabled : ''
      } ${isHighlighted ? styles.highlighted : ''}`}
      data-testid="offeringCard"
    >
      {renderOfferingCardBody()}
    </div>
  );
};

export default OfferingCard;
