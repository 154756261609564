import dompurify from 'dompurify';
import React from 'react';

import * as tableStyles from './TableShared.module.scss';

interface Props {
  data: {
    editor_data: {
      output: string;
    };
    first_column_is_header: boolean;
    has_footer: boolean;
    has_header: boolean;
  };
}

const Table = ({ data }: Props) => {
  return (
    <div
      className={`${tableStyles.tableWrap} ${
        data.has_header ? tableStyles.hasHeader : ''
      } ${data.has_footer ? tableStyles.hasFooter : ''} ${
        data.first_column_is_header ? tableStyles.firstColumnIsHeader : ''
      }`}
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(data.editor_data.output)
      }}
    />
  );
};

export default Table;
