export const accreditationOptions = [
  {
    label: 'I earn $200K+ yearly or $300K+ with my spouse/partner',
    value: 'Income'
  },
  {
    label: 'I have a net worth of 1M+ (excluding my primary residence)',
    value: 'Net Worth'
  },
  {
    label: 'I invest through an entity with $5M+ in assets',
    value: 'Qualified Purchaser'
  },
  {
    label: 'I hold a Series 7, 65, or 82 license in good standing',
    value: 'Licensed Professional'
  },
  {
    label: 'None of these',
    value: 'None'
  }
];

export const attributionOptions = [
  'Advertisements',
  'Online Research',
  'Referred by Fellow Investor',
  'Marcus and Millichap Relationship',
  'In-Person Event'
] as const;

export type AttributionOption = (typeof attributionOptions)[number];

export const attributionAdvertisementOptions = [
  'NY Times / The Daily',
  'Physician on FIRE',
  'Credible CRE',
  'Passive Pockets',
  'Dr. Chris Loo',
  'The Physicians Guide',
  'Prudent Plastic Surgeon',
  'Facebook',
  'Instagram',
  'LinkedIn',
  'YouTube',
  'TikTok',
  'Other Podcasts',
  'Another Website'
];

export const attributionResearchOptions = [
  {
    label: 'Google or Bing Search',
    value: 'Google or Bing Search'
  },
  {
    label: 'Financial Content Websites (NerdWallet, Benzinga, etc.)',
    value: 'Financial Content Websitess'
  },
  {
    label: 'AI Tools (ChatGPT, Perplexity, etc.)',
    value: 'AI Tools'
  },
  {
    label: 'TipRanks',
    value: 'TipRanks'
  },
  {
    label: 'YouTube',
    value: 'YouTube'
  },
  {
    label: 'LinkedIn',
    value: 'LinkedIn'
  },
  {
    label: 'TikTok',
    value: 'TikTok'
  },
  {
    label: 'Reddit',
    value: 'Reddit'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const attributionDetailSourceOptions = [
  "I'm a Marcus & Millichap Agent or Employee",
  "I'm a Marcus & Millichap Client",
  'I heard about the EquityMultiple partnership in the news'
];

export const attributionEventOptions = [
  'Prosperity Conference',
  'Investor Happy Hour'
];
