import { DateSelect, Input, Select } from '@equitymultiple/react-eui';
import PhoneFields from 'components/forms/PhoneFields/PhoneFields';
import {
  allResidencyStatuses,
  countryOptions
} from 'containers/Accounts/contents';
import { occupationOptions } from 'containers/Profile/content';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { setFieldProps } from 'utilities/formHelpers';
import { ssn, ssnMaskOptions } from 'utilities/masks';

import { ProfileFormValues } from '../Profile/Profile';

interface Props {
  control: Control<ProfileFormValues>;
  disableSsn?: boolean;
  errors: FieldErrors<ProfileFormValues>;
  residenceStatus: string;
}

const ProfileFields = ({
  control,
  disableSsn,
  errors,
  residenceStatus
}: Props) => {
  return (
    <>
      <h6>Personal Information</h6>
      <p>
        The Patriot Act requires us to verify the identity of every
        EquityMultiple investor as part of the mandatory &quot;Know Your
        Customer&quot; process.
      </p>

      <p className="margin-xx">
        Your account information is securely maintained in accordance with our{' '}
        <a
          href="https://www.equitymultiple.com/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        .
      </p>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="first_name"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Legal First Name"
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="last_name"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Legal Last Name"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <PhoneFields<ProfileFormValues> control={control} errors={errors} />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                disabled
                label="Email"
                placeholder="email@example.com"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col className="overflow-visible" md={6}>
          <Controller
            control={control}
            name="investor_profile_attributes.residence_status"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Residence Status"
                options={allResidencyStatuses.map(key => ({
                  label: key,
                  value: key
                }))}
              />
            )}
          />
        </Col>
        <Col className="overflow-visible" md={6}>
          <Controller
            control={control}
            name="investor_profile_attributes.country_of_citizenship"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Country of Citizenship"
                options={countryOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col className="overflow-visible" md={6}>
          <Controller
            control={control}
            name="investor_profile_attributes.dob"
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="investor_profile_attributes.ssn"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                disabled={disableSsn}
                inputMaskOptions={ssnMaskOptions}
                label={`${
                  residenceStatus === 'U.S. Permanent Resident' ||
                  residenceStatus === 'U.S. Resident'
                    ? 'SSN/ITIN'
                    : 'Social Security Number'
                }`}
                mask={ssn}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="investor_profile_attributes.occupation"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Occupation"
                options={occupationOptions}
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProfileFields;
