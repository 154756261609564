export const investmentAccountCounter = arrayOfAccounts =>
  arrayOfAccounts.filter(account => account.status === 'created').length;

const formatAccountName = investmentAccount => {
  if (investmentAccount.account_type) {
    return investmentAccount.account_type === 'ira'
      ? 'IRA'
      : investmentAccount.account_type.split(' ')[0].charAt(0).toUpperCase() +
          investmentAccount.type.split(' ')[0].slice(1);
  }
  return null;
};

const formatListOfAccountTypes = arrayOfAccounts => {
  let arrayOfAccountsOutput;
  if (arrayOfAccounts.length === 0) return '';

  if (arrayOfAccounts.length === 1) {
    arrayOfAccountsOutput = arrayOfAccounts[0] + ' Account';
  } else {
    arrayOfAccountsOutput = [...new Set(arrayOfAccounts)];
    arrayOfAccountsOutput =
      arrayOfAccountsOutput.slice(0, -1).join(', ') +
      ' and ' +
      arrayOfAccountsOutput.slice(-1) +
      ' Accounts';
  }
  return arrayOfAccountsOutput;
};

interface PersonalAccount {
  accountId?: string;
  address?: {
    address: string;
    address2: string;
    city: string;
    postal_code: string;
    region: string;
  };
  entityName?: string;
  investmentCount?: number;
  referenceId?: string;
  subTitle?: string[];
  type?: string;
}

export const prepareRowInformation = (investmentAccounts, user) => {
  const allAccounts = [];
  let personalAccount: PersonalAccount = {};
  const personalAccountSubTitle = [];
  let personalCompletedInvestmentCount = 0;
  if (investmentAccounts.length > 0) {
    investmentAccounts.forEach(investmentAccount => {
      if (investmentAccount.status === 'created') {
        if (
          ['individual', 'ira', 'joint'].includes(
            investmentAccount.account_type
          )
        ) {
          personalAccountSubTitle.push(formatAccountName(investmentAccount));
          personalCompletedInvestmentCount +=
            investmentAccount.investment_count;
          personalAccount = {
            accountId: 'personal',
            address: user.address,
            entityName: user.first_name + ' ' + user.last_name,
            investmentCount: personalCompletedInvestmentCount,
            referenceId: 'personal',
            subTitle: personalAccountSubTitle,
            type: 'personal'
          };
        } else {
          allAccounts.push({
            accountId: investmentAccount.id,
            address: {
              address: investmentAccount.address,
              address2: investmentAccount.address2,
              city: investmentAccount.city,
              postal_code: investmentAccount.postal_code,
              region: investmentAccount.region
            },
            entityName: investmentAccount.entity_name,
            investmentCount: investmentAccount.investment_count,
            referenceId: investmentAccount.reference_id,
            subTitle:
              investmentAccount.type.charAt(0).toUpperCase() +
              investmentAccount.type.slice(1) +
              ' Account',
            type: 'entity'
          });
        }
      }
    });

    if (personalAccount?.subTitle && personalAccount?.subTitle.length !== 0) {
      personalAccount.subTitle = formatListOfAccountTypes(
        personalAccount.subTitle
      );
      allAccounts.unshift(personalAccount);
    }
  }
  return allAccounts;
};

export const confirmEntityWhetherDetailsMatch = (
  firstInvestment,
  secondInvestment
) =>
  firstInvestment.address === secondInvestment.address &&
  firstInvestment.address2 === secondInvestment.address2 &&
  firstInvestment.city === secondInvestment.city &&
  firstInvestment.region === secondInvestment.region &&
  firstInvestment.postal_code === secondInvestment.postal_code;

export const confirmWhetherUserDetailsMatch = (initialUser, uploadedUser) =>
  initialUser.phone === uploadedUser.phone &&
  initialUser.address.address === uploadedUser.address.address &&
  initialUser.address.address2 === uploadedUser.address.address2 &&
  initialUser.address.city === uploadedUser.address.city &&
  initialUser.address.state === uploadedUser.address.state &&
  initialUser.address.postal_code === uploadedUser.address.postal_code;

export const locatePreviouslyEnteredAccountDetails = (
  entityAccountsSelectedForUpdate,
  accountSelectedFroReview
) =>
  entityAccountsSelectedForUpdate.find(
    account => account.reference_id === accountSelectedFroReview
  );

const determineWhetherUserHaspreviouslyEnteredEntityAccountDetails = (
  entityAccountsSelectedForUpdate,
  accountSelectedFroReview
) =>
  entityAccountsSelectedForUpdate.filter(
    account => account.id === accountSelectedFroReview
  ).length > 0;

export const hasUserPreviouslyUpdatedAccountDetailInformation = (
  investmentAccount,
  entityAccountsSelectedForUpdate,
  updatedUserInformation
) => {
  if (investmentAccount.type === 'personal')
    return Object.keys(updatedUserInformation).length > 0;

  return determineWhetherUserHaspreviouslyEnteredEntityAccountDetails(
    entityAccountsSelectedForUpdate,
    investmentAccount.accountId
  );
};

export const formatListOfInvestmentAccountsIfSelectedForUpdate = (
  initialInvestmentAccounts,
  updatedUserInformation,
  entityAccountsSelectedForUpdate
) => {
  let investmentAccountTypes = [];
  if (Object.keys(updatedUserInformation).length > 0) {
    initialInvestmentAccounts.forEach(account => {
      if (account.status === 'created' && account.type !== 'entity')
        investmentAccountTypes.push(formatAccountName(account));
    });
  }
  investmentAccountTypes.concat(entityAccountsSelectedForUpdate);
  investmentAccountTypes = [...new Set(investmentAccountTypes)];
  return formatListOfAccountTypes(investmentAccountTypes);
};

export const locateIndexOfEntityNameRequestedForUpdate = (
  investmentAccounts,
  referenceId
) =>
  investmentAccounts.map(element => element.reference_id).indexOf(referenceId);

export const formatListOfEntityAccountsIfSelectedForNameChange = (
  investmentAccounts,
  accountsSelectedForEntityNameChange
) => {
  const finalName = [];
  accountsSelectedForEntityNameChange.forEach(referenceId => {
    finalName.push(
      investmentAccounts[
        locateIndexOfEntityNameRequestedForUpdate(
          investmentAccounts,
          referenceId
        )
      ].entity_name
    );
  });
  return finalName.join(';');
};
