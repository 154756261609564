import React from 'react';
import { Col, Row } from 'react-grid-system';
import { GetInvestmentResponse } from 'types/actions/portfolio';

import OfferingCard from '../OfferingCard/OfferingCard';
import OMDetails from '../OMDetails/OMDetails';

interface Props {
  closing: GetInvestmentResponse['closing'];
  offering: GetInvestmentResponse['offering'];
}

const InvestmentOM = ({ closing, offering }: Props) => {
  return (
    <Row>
      <Col md={4}>
        <OfferingCard closing={closing} offering={offering} />
      </Col>
      <Col md={8}>
        <OMDetails offering={offering} />
      </Col>
    </Row>
  );
};

export default InvestmentOM;
