import AccountProgress from 'components/AccountProgress/AccountProgress';
import useDebouncedEffect from 'hooks/useLazyEffect';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loadMarketingData, loadOfferings } from 'redux/actions/invest';
import { User } from 'types/actions/auth';
import {
  LoadOfferingsOffering,
  LoadOfferingsResponse
} from 'types/actions/invest';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { isAccredited } from 'utilities/user';
import utils from 'utilities/utils';

import { WelcomeLetter } from './components';
import NoOfferings from './components/NoOfferings/NoOfferings';
import NotAccreditedCard from './components/NotAccreditedCard/NotAccreditedCard';
import OfferingFilters from './components/OfferingFilters/OfferingFilters';
import OfferingList from './components/OfferingList/OfferingList';
import ResourceCards from './components/ResourceCards/ResourceCards';
import { getTargetReturnValue } from './helpers';
import * as styles from './Invest.module.scss';
import { ActivePillar, FilterValueArray, InvestArticle } from './types';

const getHoldPeriodValue = (offering: LoadOfferingsOffering) => {
  let holdPeriod = Number(offering.term);
  if (offering.term_period === 'Year(s)') holdPeriod = holdPeriod * 12;

  return holdPeriod;
};

const getMinRangeValue = (
  offerings: LoadOfferingsResponse,
  valueFn: (arg0: LoadOfferingsOffering) => number
) =>
  offerings.length > 0
    ? offerings.reduce(
        (previous, current) => Math.min(previous, valueFn(current)),
        valueFn(offerings[0])
      )
    : null;

const getMaxRangeValue = (
  offerings: LoadOfferingsResponse,
  valueFn: (arg0: LoadOfferingsOffering) => number
) =>
  offerings.length > 0
    ? offerings.reduce(
        (previous, current) => Math.max(previous, valueFn(current)),
        valueFn(offerings[0])
      )
    : null;

const sendTargetReturnFilterEvent = utils.debounce(values => {
  EmAnalytics.track('Applied Return Filter', 'Investing', {
    return_lower_bound: `${values[0]}%`,
    return_upper_bound: `${values[1]}%`
  });
}, 300);

const sendHoldPeriodReturnFilterEvent = utils.debounce(values => {
  EmAnalytics.track('Applied Hold Period Filter', 'Investing', {
    hold_lower_bound: `${values[0]} month(s)`,
    hold_upper_bound: `${values[1]} month(s)`
  });
}, 300);

interface SelectInstance {
  clearValue: () => void;
}

type Props = {
  articles: InvestArticle[];
  dispatch: Dispatch;
  loadingArticles: boolean;
  loadingOfferings: boolean;
  offerings: LoadOfferingsResponse;
  user: User;
};

const Invest = ({
  articles,
  dispatch,
  loadingArticles,
  loadingOfferings,
  offerings,
  user
}: Props) => {
  const propertyTypeRef = useRef<SelectInstance>();
  const location = useLocation();
  const [activePillar, setActivePillar] = useState<ActivePillar>('all');
  const [filteredPropertyType, setFilteredPropertyType] = useState([]);
  const [filteredTargetReturn, setFilteredTargetReturn] =
    useState<FilterValueArray>([0, 0]);
  const [filteredHoldPeriod, setFilteredHoldPeriod] =
    useState<FilterValueArray>([0, 0]);
  const [targetReturnRange, setTargetReturnRange] = useState<FilterValueArray>([
    0, 0
  ]);
  const [holdPeriodRange, setHoldPeriodRange] = useState<FilterValueArray>([
    0, 0
  ]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [filteredOfferings, setFilteredOfferings] = useState([]);

  let showWelcomeLetter = false;
  const query = queryString.parse(location.search);
  if (query?.show === 'welcome' && user.investor_profile.stage === 'invest') {
    const welcomeLetterShown =
      utils.getCookie('welcome-letter-shown') === user.id.toString();
    if (!welcomeLetterShown) showWelcomeLetter = true;
  }

  const getFilteredOfferings = () => {
    let newFilteredOfferings = offerings;

    newFilteredOfferings = offerings.filter(offering => {
      // Pillar
      const pillarMatches =
        activePillar === 'all' || offering.pillar === activePillar;

      // Property Type
      const propertyTypeMatches =
        !filteredPropertyType.length ||
        filteredPropertyType.includes(offering.asset_type);

      // Target Return
      const returnValue = getTargetReturnValue(offering);
      const targetReturnMatches =
        !filteredTargetReturn.length ||
        (filteredTargetReturn[0] <= returnValue &&
          filteredTargetReturn[1] >= returnValue);

      // Hold Period
      const holdPeriod = getHoldPeriodValue(offering);
      const holdPeriodMatches =
        !filteredHoldPeriod.length ||
        (filteredHoldPeriod[0] <= holdPeriod &&
          filteredHoldPeriod[1] >= holdPeriod);

      return (
        pillarMatches &&
        propertyTypeMatches &&
        targetReturnMatches &&
        holdPeriodMatches
      );
    });

    return newFilteredOfferings;
  };

  useEffect(() => {
    document.title = 'Invest | EquityMultiple';
    document.querySelector('body').style.backgroundColor = '#f8f8f8';

    dispatch(loadOfferings());
    dispatch(loadMarketingData());
  }, [dispatch]);

  useEffect(() => {
    if (offerings) {
      const newPropertyTypeOptions = [];
      const minReturn = getMinRangeValue(offerings, getTargetReturnValue);
      const maxReturn = getMaxRangeValue(offerings, getTargetReturnValue);
      const newTargetReturnRange: FilterValueArray = [minReturn, maxReturn];
      const minHold = getMinRangeValue(offerings, getHoldPeriodValue);
      const maxHold = getMaxRangeValue(offerings, getHoldPeriodValue);
      const newHoldPeriodRange: FilterValueArray = [minHold, maxHold];

      offerings.forEach(offering => {
        const assetType = offering.asset_type;

        if (
          !newPropertyTypeOptions.some(option => option.value === assetType)
        ) {
          newPropertyTypeOptions.push({
            label: assetType,
            value: assetType
          });
        }
      });

      setPropertyTypeOptions(newPropertyTypeOptions);
      setTargetReturnRange(newTargetReturnRange);
      setHoldPeriodRange(newHoldPeriodRange);
      setFilteredTargetReturn(newTargetReturnRange);
      setFilteredHoldPeriod(newHoldPeriodRange);
      setFilteredOfferings(offerings);
    }
  }, [offerings]);

  useEffect(() => {
    const newFilteredOfferings = getFilteredOfferings();

    setFilteredOfferings(newFilteredOfferings);
  }, [activePillar, filteredPropertyType]); // eslint-disable-line react-hooks/exhaustive-deps

  useDebouncedEffect(
    () => {
      const newFilteredOfferings = getFilteredOfferings();

      setFilteredOfferings(newFilteredOfferings);
    },
    [filteredTargetReturn, filteredHoldPeriod],
    300
  );

  const showAccountProgress = user?.investor_profile?.stage === 'activate';

  const showOfferings = isAccredited(user);

  const handlePillarChange = (pillar: ActivePillar) => {
    const newPillar =
      pillar !== 'all' && pillar === activePillar ? 'all' : pillar;

    setActivePillar(newPillar);

    EmAnalytics.track('Applied Pillar Filter', 'Investing', {
      pillar: newPillar
    });
  };

  const handleClearFilter = () => {
    setActivePillar('all');
    setFilteredPropertyType([]);
    setFilteredTargetReturn(targetReturnRange);
    setFilteredHoldPeriod(holdPeriodRange);
    if (propertyTypeRef?.current) propertyTypeRef?.current.clearValue();
  };

  const handlePropertyTypeChange = value => {
    setFilteredPropertyType(value);
  };

  const handleTargetReturnChange = values => {
    setFilteredTargetReturn(values);

    sendTargetReturnFilterEvent(values);
  };

  const handleHoldPeriodChange = values => {
    setFilteredHoldPeriod(values);

    sendHoldPeriodReturnFilterEvent(values);
  };

  const hasOfferings = filteredOfferings?.length > 0;

  return (
    <div>
      {showWelcomeLetter && (
        <WelcomeLetter
          irRep={user.investor_profile?.representative_meeting_link}
          name={user.first_name}
          userId={user.id}
        />
      )}
      <Container className="container-wide">
        {showAccountProgress && (
          <AccountProgress signupStage="invest" user={user} />
        )}

        {showOfferings ? (
          <>
            <div className="text-center">
              <h1 className={styles.heading}>
                Get Ready for Easier Real Estate Investing
              </h1>
              <div className={styles.sectionSubheading}>
                Investments to fit your strategy and goals.
              </div>
            </div>
            <OfferingFilters
              activeHoldPeriod={filteredHoldPeriod}
              activePillar={activePillar}
              activePropertyType={filteredPropertyType}
              activeTargetReturn={filteredTargetReturn}
              holdPeriodRange={holdPeriodRange}
              onClearFilter={handleClearFilter}
              onHoldPeriodChange={handleHoldPeriodChange}
              onPillarChange={handlePillarChange}
              onPropertyTypeChange={handlePropertyTypeChange}
              onTargetReturnChange={handleTargetReturnChange}
              propertyTypeOptions={propertyTypeOptions}
              propertyTypeRef={propertyTypeRef}
              targetReturnRange={targetReturnRange}
            />
            {hasOfferings || loadingOfferings ? (
              <div className={styles.contentWrap}>
                <section className={styles.mainContent}>
                  <OfferingList
                    loading={loadingOfferings}
                    offerings={filteredOfferings}
                    user={user}
                  />
                </section>
                <aside className={styles.sidebar}>
                  <ResourceCards
                    activePillar={activePillar}
                    articles={articles}
                    loading={loadingArticles}
                  />
                </aside>
              </div>
            ) : (
              <NoOfferings onClearFilter={handleClearFilter} />
            )}
          </>
        ) : (
          <NotAccreditedCard />
        )}
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    articles: store.invest.articles,
    loadingArticles: store.invest.loadingArticles,
    loadingOfferings: store.invest.loadingOfferings,
    offerings: store.invest.offerings,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Invest);
