/* eslint-disable react-hooks/exhaustive-deps */

import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef
} from 'react';
import utils from 'utilities/utils';

// Performs a debounced side effect in response to a dependency change
const useDebouncedEffect = (
  effect: EffectCallback,
  deps: DependencyList = [],
  wait = 300
) => {
  const cleanUp = useRef<(() => void) | void>();
  const effectRef = useRef<EffectCallback>();
  effectRef.current = useCallback(effect, deps);
  const lazyEffect = useCallback(
    utils.debounce(() => (cleanUp.current = effectRef.current?.()), wait),
    []
  );

  useEffect(lazyEffect, deps);

  useEffect(() => {
    return () =>
      cleanUp.current instanceof Function ? cleanUp.current() : undefined;
  }, []);
};

export default useDebouncedEffect;
