import { ChartDataPoint } from 'containers/Portfolio/types';
import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

interface Props {
  dataPoints: ChartDataPoint[];
  width?: number | string;
}

const DonutChart = ({ dataPoints, width }: Props) => {
  return (
    <ResponsiveContainer aspect={1} width={width || '100%'}>
      <PieChart>
        <Pie
          cx="50%"
          cy="50%"
          data={dataPoints}
          dataKey="value"
          innerRadius="50%"
          isAnimationActive={false}
          outerRadius="100%"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
