import { Card } from '@equitymultiple/react-eui';
import React from 'react';
import { connect } from 'react-redux';
import { User } from 'types/actions/auth';
import { twoFaSetupRequired } from 'utilities/user';

import * as sharedStyles from '../../SettingsShared.module.scss';
import SettingUpdateEmailForm from '../forms/SettingUpdateEmailForm';
import SettingUpdatePasswordForm from '../forms/SettingUpdatePasswordForm';
import TwoFactorAuthentication from '../forms/TwoFactorAuthentication/TwoFactorAuthentication';
import UserDevice from '../forms/UserDevice/UserDevice';

interface Props {
  user: User;
}

const Security = ({ user }: Props) => {
  const twoFaSetupIsRequired = twoFaSetupRequired(user);

  return (
    <div data-testid="securityTab">
      <Card className={sharedStyles.settingsCard}>
        <h6>Two-factor Authentication</h6>
        <TwoFactorAuthentication />
      </Card>

      {!twoFaSetupIsRequired && (
        <>
          <Card className={sharedStyles.settingsCard}>
            <h6>Change Your Password</h6>
            <SettingUpdatePasswordForm />
          </Card>

          <Card className={sharedStyles.settingsCard}>
            <h6>Update Email</h6>
            <SettingUpdateEmailForm user={user} />
          </Card>

          <Card className={sharedStyles.settingsCard}>
            <h6>Your Devices</h6>
            <UserDevice />
          </Card>
        </>
      )}
    </div>
  );
};

function mapStateToProps(store) {
  return { user: store.auth.user };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Security);
