import * as yup from 'yup';

import { yupBool, yupString } from '../../utilities/yupValidations';

export const confidentialityAgreementSchema = yup.object().shape({
  scrolledToBottom: yupBool
    .oneOf(
      [true],
      'You must read the entire confidentiality agreement. Please scroll to the bottom of the agreement'
    )
    .required(
      'You must read the entire confidentiality agreement. Please scroll to the bottom of the agreement'
    ),
  user: yup.object().shape({
    net_worth: yupString
      .notRequired()
      .test(
        'Required for non-entity accounts',
        'Net worth is required',
        function (value) {
          const accountType = this.parent.investment_account_type;
          if (accountType !== 'entity' && !value) return false;
          return true;
        }
      ),
    portfolio_value: yupString
      .notRequired()
      .test(
        'Required for entity accounts',
        'Portfolio value is required',
        function (value) {
          const accountType = this.parent.investment_account_type;
          if (accountType === 'entity' && !value) return false;
          return true;
        }
      ),
    qualified_purchaser: yupString.required(
      'Qualified purchaser option is required'
    ),
    signature: yupString
      .required('Signature is required')
      .test(
        'Signature matches name',
        'Please type your name exactly as it is registered with us',
        function (value) {
          const fullName = this.parent.fullName;
          if (value !== fullName) return false;
          return true;
        }
      )
  })
});
