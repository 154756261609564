import { Button, Password } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import SetPasswordFields from 'components/forms/SetPasswordFields/SetPasswordFields';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { updatePassword } from 'redux/actions/user-settings';
import { UpdatePasswordPayload } from 'types/actions/user-settings';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';
import { InferType } from 'yup';

import { updatePasswordSchema } from '../validation';

interface Props {
  dispatch: Dispatch;
}

type UpdatePasswordValues = InferType<typeof updatePasswordSchema>;

const SettingUpdatePasswordForm = ({ dispatch }: Props) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm({ resolver: yupResolver(updatePasswordSchema) });

  const onSubmit: SubmitHandler<UpdatePasswordValues> = values => {
    return dispatch(updatePassword({ user: values } as UpdatePasswordPayload))
      .then(res => {
        humane.notice(res.message);
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="current_password"
        render={({ field }) => (
          <Password
            {...setFieldProps(field, errors)}
            label="Current Password"
          />
        )}
      />
      <SetPasswordFields<UpdatePasswordValues>
        changePassword
        control={control}
        errors={errors}
        fullWidthFields
      />
      <FormError errors={errors} />
      <Button className="float-right" loading={isSubmitting} type="submit">
        Update Password
      </Button>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(SettingUpdatePasswordForm);
