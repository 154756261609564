import { AnyAction } from 'redux';

import * as actions from '../actions/documents';
const initialState = {
  documents: {
    account_documents: [],
    investment_documents: [],
    tax_documents: []
  },
  loading: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        documents: action.result,
        loaded: true,
        loading: false
      };

    case actions.LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        loadErrors: action.error,
        loading: false
      };

    default:
      return state;
  }
};
