import { MultiSelectFilter } from '@equitymultiple/react-eui';
import React from 'react';
import { LoadPostsOfferingsResponse } from 'types/actions/offerings';

import { sortOfferings } from '../helpers';
import { labels } from './constants';

type Props = {
  offerings: LoadPostsOfferingsResponse['offerings'];
  selectedLabels: string[];
  selectedOfferings: number[];
  setFilterId: (filters: { label: string[]; offering: number[] }) => void;
};

const PostFilters = React.memo(
  ({ offerings, selectedLabels, selectedOfferings, setFilterId }: Props) => {
    const sortedOfferings = [...sortOfferings(offerings)];
    const offeringOptions = sortedOfferings.map(offering => ({
      label: offering.title,
      value: offering.id
    }));

    const handleFilterChange = filters => {
      const [label, offering] = filters;
      setFilterId({ label, offering });
    };

    return (
      <div className="stickySidebarWrap">
        {offerings.length > 0 && (
          <div>
            <MultiSelectFilter
              filterGroups={[
                {
                  hasClearAll: true,
                  options: labels,
                  selectedOptions: [...selectedLabels],
                  title: 'Post Type'
                },
                {
                  hasClearAll: true,
                  options: offeringOptions,
                  selectedOptions: [...selectedOfferings],
                  title: 'Offerings'
                }
              ]}
              mobileLayoutEnabled={false}
              onChange={handleFilterChange}
            />
          </div>
        )}
      </div>
    );
  },
  (prev, next) =>
    JSON.stringify(prev.selectedLabels) ===
      JSON.stringify(next.selectedLabels) ||
    JSON.stringify(prev.selectedOfferings) ===
      JSON.stringify(next.selectedOfferings)
);

PostFilters.displayName = 'PostFilters';

export default PostFilters;
