import { Button } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import moment from 'moment';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  loadInvestmentAccount,
  loadInvestmentAccountDocuments,
  updateInvestmentAccount,
  uploadInvestmentAccountDocuments
} from 'redux/actions/account';
import {
  IdDocument,
  JointInvestmentAccount,
  LoadInvestmentAccountAccount,
  UploadInvestmentAccountDocumentsPayload
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';
import { InferType } from 'yup';

import { isComplete } from '../helpers';
import { jointSecondaryHolderIdentificationSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import IdentificationDocumentFields from './FormFields/IdentificationDocumentFields';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  idDoc: IdDocument;
  investmentAccount: LoadInvestmentAccountAccount<JointInvestmentAccount>;
  loading: boolean;
  residenceStatus: string;
  uploading: boolean;
}

type JointSecondaryHolderIdentificationFormValues = InferType<
  typeof jointSecondaryHolderIdentificationSchema
>;

const JointSecondaryHolderIdentification = ({
  dispatch,
  idDoc,
  investmentAccount,
  loading,
  residenceStatus,
  uploading
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const defaultValues = {
    investment_account:
      idDoc && investmentAccount
        ? {
            account_holder_2_confirm_valid_id: true,
            account_holder_2_dob:
              investmentAccount.account_holder_2_dob &&
              moment
                .utc(investmentAccount.account_holder_2_dob)
                .format('MM/DD/YYYY'),
            account_holder_2_id_document: idDoc.name,
            account_holder_2_id_expiration: idDoc.expiry
              ? moment.utc(idDoc.expiry).format('MM/DD/YYYY')
              : '',
            account_holder_2_id_type: idDoc.type,
            account_holder_2_passport: null
          }
        : {}
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue
  } = useForm({
    resolver: yupResolver(jointSecondaryHolderIdentificationSchema),
    values: defaultValues
  });

  useEffect(() => {
    document.title =
      'My Accounts | Joint Account Secondary Account Holder Identification';
    dispatch(loadInvestmentAccount(params.reference_id))
      .then(() =>
        dispatch(loadInvestmentAccountDocuments(params.reference_id)).catch(
          error => handleErrorResponse(navigate, error)
        )
      )
      .catch(error => handleErrorResponse(navigate, error));
  }, [dispatch, params, navigate]);

  const onSubmit: SubmitHandler<
    JointSecondaryHolderIdentificationFormValues
  > = values => {
    const submitValues = values as UploadInvestmentAccountDocumentsPayload;

    submitValues.investment_account.id = investmentAccount.id;
    submitValues.investment_account.account = 'secondary';
    submitValues.file2 = values.investment_account
      .account_holder_2_id_document as File;
    return dispatch(uploadInvestmentAccountDocuments(submitValues))
      .then(() => {
        dispatch(updateInvestmentAccount(values));
        EmAnalytics.track(
          'Submits Joint Identification Document',
          'Onboarding'
        );
        if (isComplete(investmentAccount)) {
          navigate(
            `/accounts/update/joint/documents/${investmentAccount.reference_id}`
          );
        } else {
          navigate(
            `/accounts/wizard/joint/w9/${investmentAccount.reference_id}`
          );
        }
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const handleFileRemoval = () => {
    setValue('investment_account.account_holder_2_id_document', null);
  };

  const handleUpload = file => {
    setValue('investment_account.account_holder_2_id_document', file);
  };

  return (
    <AccountWrap account={investmentAccount} loading={loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="margin-top-0">
          Verify Secondary Account Holder's Identity
        </h4>
        <p className="margin-xx">
          Please upload a color copy of a U.S government-issued document. The
          name reflected on the identification document must match the name
          associated with the secondary account holder.
        </p>
        <IdentificationDocumentFields<JointSecondaryHolderIdentificationFormValues>
          control={control}
          errors={errors}
          existingDocument={idDoc}
          isSecondaryAccountHolder
          onRemove={handleFileRemoval}
          onUpload={handleUpload}
          residenceStatus={residenceStatus}
          uploading={uploading}
        />

        <FormError errors={errors} />

        <div className="forwardBackButtonWrapCompact">
          <Button
            loading={isSubmitting}
            style={{ width: 160 }}
            type="submit"
            variant="orange"
          >
            {isComplete(investmentAccount) ? 'Update' : 'Continue'}
          </Button>
          <BackLink
            investmentAccount={investmentAccount}
            page="Joint Account Identification"
          />
        </div>
      </form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  const residenceStatus = state.auth.user?.investor_profile?.residence_status;
  const idDoc = state.account.documents?.account_holder_2_id_document;

  return {
    idDoc,
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading,
    residenceStatus,
    uploading: state.account.uploading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(JointSecondaryHolderIdentification);
