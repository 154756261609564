import { Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import React from 'react';
import { LoadPostsOfferingsResponse } from 'types/actions/offerings';

import * as styles from './PostList.module.scss';

type Props = {
  filterByOfferings: number[];
  hasPosts: boolean;
  loading: boolean;
  offerings: LoadPostsOfferingsResponse['offerings'];
  readPosts: React.JSX.Element[];
  unreadPosts: React.JSX.Element[];
};
const PostList = ({
  filterByOfferings,
  hasPosts,
  loading,
  offerings,
  readPosts,
  unreadPosts
}: Props) => {
  const noPostsForOffering =
    filterByOfferings.length === 1 && offerings.length > 0;

  return (
    <div>
      {loading ? (
        <Card className={styles.postCard}>
          <div>
            <EMLoadingIcon className={styles.icon} />
          </div>
        </Card>
      ) : (
        <>
          {!hasPosts && (
            <Card className={styles.postCard}>
              <h3 className="margin-top-0">
                {noPostsForOffering
                  ? 'There are no recent posts regarding this offering'
                  : 'You currently have no activity updates. Check back periodically to see updates on your investments.'}
              </h3>
            </Card>
          )}

          {unreadPosts.length > 0 && unreadPosts}

          {unreadPosts.length > 0 && readPosts.length > 0 && (
            <h3 className="margin-top-0">Older Posts</h3>
          )}

          {readPosts.length > 0 && readPosts}
        </>
      )}
    </div>
  );
};
PostList.displayName = 'PostList';

export default PostList;
