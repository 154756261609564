import { DateSelect, Input, Select } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { setFieldProps } from 'utilities/formHelpers';
import {
  percentageMask,
  percentageMaskOptions,
  phoneMask,
  ssn,
  ssnMaskOptions
} from 'utilities/masks';

import { stateOptions } from '../contents';
import { BeneficialFormValues } from './BeneficialNew';

interface Props {
  control: Control<BeneficialFormValues>;
  errors: FieldErrors<BeneficialFormValues>;
  hasSsn: boolean;
}

const BeneficialOwnerFields = ({ control, errors, hasSsn }: Props) => {
  return (
    <>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.title"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Title" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.percentage_ownership"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={percentageMaskOptions}
                label="Percentage Ownership"
                mask={percentageMask}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.first_name"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="First Name" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.last_name"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Last Name" />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col className="overflow-visible" md={7}>
          <Controller
            control={control}
            name="beneficial_owner.dob"
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
        <Col md={5}>
          <Controller
            control={control}
            name="beneficial_owner.ssn"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                disabled={hasSsn}
                inputMaskOptions={ssnMaskOptions}
                label="SSN"
                mask={ssn}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.email"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Email" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.phone"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Phone Number"
                mask={phoneMask}
              />
            )}
          />
        </Col>
      </Row>
      <h5>Address Information</h5>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.address"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="beneficial_owner.address2"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2"
                placeholder="Apartment number, etc."
              />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={4}>
          <Controller
            control={control}
            name="beneficial_owner.city"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col className="overflow-visible" md={4}>
          <Controller
            control={control}
            name="beneficial_owner.state"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                options={stateOptions}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="beneficial_owner.postal_code"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="99999"
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default BeneficialOwnerFields;
