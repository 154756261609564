import { Button, Modal, RadioButton, Select } from '@equitymultiple/react-eui';
import FormError from 'components/FormError/FormError';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Dispatch } from 'types/redux';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';

import { generateAccountStatement } from '../../../../redux/actions/portfolio';
import EmAnalytics from '../../../../utilities/em_analytics';
import {
  dateRangeOptions,
  effectiveDateDescription,
  transferDateDescription
} from '../../constants';
import * as styles from './AccountStatementModal.module.scss';

type Params = {
  date_type: string;
  end_date?: string;
  start_date?: string;
};

interface FormValues {
  dateRange?: string;
  dateType?: 'date_posted' | 'effective_date';
}

interface Props {
  dispatch: Dispatch;
  open?: boolean;
  toggleOpen: () => void;
}

const AccountStatementModal = ({ dispatch, open, toggleOpen }: Props) => {
  const defaultValues = {
    dateRange: 'all',
    dateType: 'date_posted' as FormValues['dateType']
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    watch
  } = useForm<FormValues>({
    values: defaultValues
  });

  const formValues = watch();

  const onSubmit = values => {
    let dateRange;
    try {
      dateRange = JSON.parse(formValues.dateRange);
    } catch {
      dateRange = formValues.dateRange;
    }

    const params: Params = {
      date_type: values.dateType
    };

    if (formValues.dateRange && formValues.dateRange !== 'all') {
      params.start_date = dateRange.startDate;
      params.end_date = dateRange.endDate;
    }

    toggleOpen();

    dispatch(generateAccountStatement(params));

    EmAnalytics.track('Investor Generated Account Statement', '', {
      timePeriod: dateRange
    });
  };

  const handleClose = () => {
    toggleOpen();

    // Prevent seeing the fields change while the modal disappears
    setTimeout(() => {
      reset();
    }, 200);
  };

  const getDateTypeLabel = dateType =>
    dateType === 'date_posted' ? 'Transfer Date' : 'Effective Date';

  return (
    <Modal
      className={styles.modal}
      onClose={toggleOpen}
      open={open}
      title={<h6>Account Statement Download</h6>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="margin-xx margin-top-xx">
          Download your account statement by selecting the time period:
        </p>
        <Controller
          control={control}
          name="dateType"
          render={({ field }) => (
            <>
              <div className="margin-x">
                <RadioButton
                  {...setRadioFieldProps(field, errors, 'date_posted')}
                  hideError
                  label={transferDateDescription}
                />
              </div>
              <div className="margin-xx">
                <RadioButton
                  {...setRadioFieldProps(field, errors, 'effective_date')}
                  label={effectiveDateDescription}
                />
              </div>
            </>
          )}
        />

        <Controller
          control={control}
          name="dateRange"
          render={({ field }) => (
            <Select
              {...setFieldProps(field, errors)}
              label={getDateTypeLabel(formValues.dateType)}
              options={dateRangeOptions}
            />
          )}
        />

        <FormError errors={errors} />

        <div className="button-wrap">
          <Button onClick={handleClose} variant="ghost">
            Cancel
          </Button>
          <Button loading={isSubmitting} style={{ width: 180 }} type="submit">
            Download
          </Button>
        </div>
      </form>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(AccountStatementModal);
