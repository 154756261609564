import ResourceCard from 'components/ResourceCard/ResourceCard';
import { ActivePillar, InvestArticle } from 'containers/Invest/types';
import React from 'react';

import * as styles from './ResourceCards.module.scss';

interface Props {
  activePillar: ActivePillar;
  articles: InvestArticle[];
  loading: boolean;
}

const LoadingSkeleton = () => (
  <div className={styles.cards} data-testid="resourceCardsLoadingSkeleton">
    <ResourceCard loading />
    <ResourceCard loading />
    <ResourceCard loading />
  </div>
);

const ResourceCards = ({ activePillar, articles, loading }: Props) => {
  const visibleArticles = articles?.filter(
    article => activePillar === 'all' || article.pillar.includes(activePillar)
  );

  return loading ? (
    <LoadingSkeleton />
  ) : (
    <div className={styles.cards}>
      {visibleArticles?.map(article => (
        <ResourceCard
          key={article.title}
          label={article.label}
          title={article.title}
          url={article.url}
        />
      ))}
    </div>
  );
};

export default ResourceCards;
