import { AnalyticsBrowser } from '@segment/analytics-next';

import { campaignParams } from './analyticsHelpers';
import { lrxSendPage } from './leadsRx';
import utils from './utils';

const isTestEnvironment =
  process.env.NODE_ENV === 'test' || process.env.REACT_APP_ENV === 'test';

const analytics =
  !isTestEnvironment &&
  AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY });

const getVwoUuid = () => utils.getCookie('_vwo_uuid') || '';

const getSegmentUserEmail = async () => {
  try {
    const user = await analytics.user();
    const email = user.traits().email;
    return email;
  } catch {
    return null;
  }
};

class EmAnalytics {
  static async identify(userId: number, traits: { email?: string } = {}) {
    if (isTestEnvironment) return;

    // Reset the user analytics if the identify email has changed
    const identifiedUserEmail = await getSegmentUserEmail();
    if (identifiedUserEmail && identifiedUserEmail !== traits.email) {
      analytics.reset();
    }

    const userIdString = userId.toString();
    const userHash = utils.getCookie('intercom_user_hash');

    let destinationTraits = {};

    if (userHash) {
      destinationTraits = {
        Intercom: {
          user_hash: userHash
        }
      };
    }

    analytics.identify(
      userIdString,
      {
        vwo_uuid: getVwoUuid(),
        ...traits
      },
      destinationTraits
    );
  }

  static page(name, properties = {}, context = {} as Record<string, unknown>) {
    if (isTestEnvironment) return;

    context.campaign = campaignParams();

    analytics.page(
      name,
      {
        vwo_uuid: getVwoUuid(),
        ...properties
      },
      {
        context,
        integrations: {
          Impact: false
        }
      }
    );
    lrxSendPage();
  }

  static async track(
    event,
    category,
    data = {} as Record<string, unknown>,
    integrations = {},
    context = {}
  ) {
    if (isTestEnvironment) return;

    const identifiedUserEmail = await getSegmentUserEmail();
    if (identifiedUserEmail) {
      data.email = identifiedUserEmail;
    }

    analytics.track(
      event,
      {
        action: event,
        category,
        vwo_uuid: getVwoUuid(),
        ...data
      },
      {
        context,
        integrations: {
          Impact: false,
          ...integrations
        }
      }
    );
  }
}

export default EmAnalytics;
