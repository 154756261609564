import dompurify from 'dompurify';
import React from 'react';

interface Props {
  sanitize?: boolean;
  text: string;
}

const RichText = ({ sanitize, text }: Props) => {
  const html = sanitize ? dompurify.sanitize(text) : text;

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RichText;
