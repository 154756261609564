import * as Sentry from '@sentry/react';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadAuth } from '../../redux/actions/auth';
import { User } from '../../types/actions/auth';
import { Dispatch } from '../../types/redux';
import { loggedOutPaths, noHeaderPaths } from '../../utilities/constants';
import EmAnalytics from '../../utilities/em_analytics';
import humane from '../../utilities/humane';
import { twoFaSetupRequired } from '../../utilities/user';
import utils from '../../utilities/utils';
import AdBlockerModal from './AdBlockerModal/AdBlockerModal';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const onNoHeaderPath = path => noHeaderPaths.includes(path);
const onLoggedOutPath = path => loggedOutPaths.includes(path);
const securityRoute = '/settings/security';

type Props = PropsWithChildren & {
  adBlockerModalContent: string;
  currentUser: User;
  dispatch: Dispatch;
  loaded: boolean;
};

const App = ({
  adBlockerModalContent,
  children,
  currentUser,
  dispatch,
  loaded
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathnameRef = useRef(location.pathname);
  const searchRef = useRef(location.search);
  const currentUserRef = useRef(currentUser);

  const handleScroll = () => {
    const { hash } = window.location;
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const fetchUser = async () => {
    if (!loaded) {
      return dispatch(loadAuth()).then(user => {
        if (user?.id && onLoggedOutPath(location.pathname)) {
          humane.error('You are already signed in');
          navigate('/');
        }
      });
    } else return;
  };

  const identifyUser = () => {
    if (currentUser?.id) {
      EmAnalytics.identify(currentUser.id, { email: currentUser.email });
    }
  };

  const sendPage = () => {
    setTimeout(() => {
      EmAnalytics.page(document.title);
    }, 1000);
  };

  const setInitialUserData = () => {
    if (currentUser && Object.keys(currentUser).length) {
      const {
        email,
        first_name: firstName,
        id,
        last_name: lastName
      } = currentUser;

      if (Sentry) {
        Sentry.setUser({
          email,
          firstName,
          id,
          lastName
        });
      }

      if (currentUser.confirmed) {
        // tracking codes
        if (process.env.REACT_APP_ENV === 'production')
          window.adroll_email = currentUser.email;

        utils.setLocalStorage('logged_in', 'true');
      }
    }
  };

  const pageLoadFunctions = () => {
    sendPage();
    handleScroll();
  };

  const handleTwoFaRequired = () => {
    if (twoFaSetupRequired(currentUser)) {
      if (location.pathname !== securityRoute)
        navigate(securityRoute, { replace: true });
    }
  };

  const isLoggedIn = currentUser && Object.keys(currentUser).length;
  const showHeaderFooter = isLoggedIn && !onNoHeaderPath(location.pathname);
  const twoFaSetupIsRequired = twoFaSetupRequired(currentUser);

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleTwoFaRequired();
    setInitialUserData();
    identifyUser();
    pageLoadFunctions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    const currentPage = pathnameRef.current + searchRef.current;
    const nextPage = location.pathname + location.search;

    if (currentPage !== nextPage) {
      fetchUser().then(() => {
        pageLoadFunctions();
      });
    }

    if (
      currentUser &&
      Object.keys(currentUser).length &&
      (!currentUserRef.current || !Object.keys(currentUserRef.current).length)
    )
      setInitialUserData();

    pathnameRef.current = location.pathname;
    searchRef.current = location.search;
    currentUserRef.current = currentUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, location.pathname, location.search]);

  return (
    <>
      {adBlockerModalContent && (
        <AdBlockerModal content={adBlockerModalContent} dispatch={dispatch} />
      )}
      {showHeaderFooter && !twoFaSetupIsRequired && (
        <Header currentUser={currentUser} location={location.pathname} />
      )}
      <div className="content-wrapper clearfix-after">{children}</div>
      {showHeaderFooter && <Footer />}
    </>
  );
};

function mapStateToProps(store) {
  return {
    adBlockerModalContent: store.modals.adBlockerModalContent,
    currentUser: store.auth.user,
    loaded: store.auth.loaded
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(App);
