import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import { TwoFaFormValues } from 'containers/SignUp/types';
import Back from 'images/icons/arrow-back.svg';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  requestPhoneVerificationCode,
  verifyPhoneVerificationCode
} from 'redux/actions/user-settings';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';
import { yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const smsConfirmationSchema = yup.object().shape({
  code: yupString.required('Code is required')
});

interface FormValues {
  code?: string;
}

export interface Props {
  changePhone: () => void;
  dispatch: Dispatch;
  onSubmitSuccess: (values: FormValues) => void;
  phoneFormValues: TwoFaFormValues;
  requestingCode?: boolean;
}

const SmsConfirmationForm = ({
  changePhone,
  dispatch,
  onSubmitSuccess,
  phoneFormValues,
  requestingCode
}: Props) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormValues>({
    resolver: yupResolver(smsConfirmationSchema)
  });

  const loading = isSubmitting || requestingCode;

  const getFormattedPhoneNumber = () => {
    if (phoneFormValues) {
      return `+${phoneFormValues.country_code} ${phoneFormValues.phone}`;
    }
    return '';
  };

  const phoneNumber = getFormattedPhoneNumber();

  const onSubmit = async values => {
    const token = await getRecaptchaToken('verifySmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        code: values.code,
        ...phoneFormValues
      }
    };

    return dispatch(verifyPhoneVerificationCode(submitValues))
      .then(res => {
        onSubmitSuccess(res);
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  const handleResendCode = async () => {
    const token = await getRecaptchaToken('requestSmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        country_code: phoneFormValues.country_code,
        phone: phoneFormValues.phone
      }
    };

    return dispatch(requestPhoneVerificationCode(submitValues))
      .then(() => {
        humane.notice(`Code resent to ${getFormattedPhoneNumber()}`);
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p data-testid="phoneSubheading">
        Please enter the code we've sent in the text message to
        <br />
        <strong>{phoneNumber}</strong>
      </p>
      <p className="margin-xx">
        <button
          className="text-link"
          disabled={loading}
          onClick={changePhone}
          type="button"
        >
          Change Phone Number
        </button>
      </p>
      <p className="margin-xx">
        Didn't receive the code?{' '}
        <button
          className="text-link"
          disabled={loading}
          onClick={handleResendCode}
          type="button"
        >
          Resend
        </button>
      </p>

      <Controller
        control={control}
        name="code"
        render={({ field }) => (
          <Input
            {...setFieldProps(field, errors)}
            className="input-fixed-width-wide"
            label="Code from SMS"
          />
        )}
      />

      <FormError errors={errors} />

      <div className="forwardBackButtonWrapCompact">
        <Button
          className="button-fixed-width margin-top-xx"
          loading={loading}
          type="submit"
          variant="orange"
        >
          Continue
        </Button>
        <Button
          className="button-fixed-width margin-top-xx arrowBackButton"
          disabled={loading}
          onClick={changePhone}
          variant="outlined"
        >
          <Back />
        </Button>
      </div>
    </form>
  );
};

function mapStateToProps(store) {
  return {
    requestingCode: store.userSettings.requestingCode
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(SmsConfirmationForm);
