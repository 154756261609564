import { StatusLabel, Tooltip } from '@equitymultiple/react-eui';
import React, { ReactElement } from 'react';

import {
  closingStages,
  closingStageTooltips
} from '../../containers/Invest/components/constants';
import { ClosingStage } from '../../types/api/closing';
import * as styles from './ClosingStageLabel.module.scss';

interface Props {
  stage: ClosingStage;
}

const getLabel = (closingStage: ClosingStage) => {
  let label: ReactElement | string = closingStages[closingStage];

  if (['interest', 'waitlist'].includes(closingStage)) {
    label = (
      <div className={styles.labelWrap}>
        {label}{' '}
        <Tooltip
          className="info-icon-margin-left"
          infoIcon
          infoIconColor="#fff"
          tooltipContent={
            closingStageTooltips[closingStage as 'interest' | 'waitlist']
          }
        />
      </div>
    );
  }

  return label;
};

const getColor = (closingStage: ClosingStage) => {
  switch (closingStage) {
    case 'active':
    case 'limited_access':
      return '#f06b21';
    case 'cashflowing':
      return '#213565';
    case 'draft':
    case 'exited':
      return '#364976';
    case 'funded':
      return '#062164';
    case 'interest':
      return '#694bcc';
    case 'waitlist':
      return '#ccb48b';
    default:
      return '#062164';
  }
};

const ClosingStageLabel = ({ stage }: Props) => {
  const formattedStage = getLabel(stage);

  return <StatusLabel color={getColor(stage)} label={formattedStage} />;
};

export default ClosingStageLabel;
