import { GetPaymentsResponse } from 'types/actions/payments';
import { AsyncActionCreator } from 'types/redux';

export const GET_PAYMENTS = 'payments/GET_PAYMENTS';
export const GET_PAYMENTS_SUCCESS = 'payments/GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAIL = 'payments/GET_PAYMENTS_FAIL';
export const RESET_PAYMENTS = 'payments/RESET_PAYMENTS';

export function getPayments(params): AsyncActionCreator<GetPaymentsResponse> {
  const queryString = new URLSearchParams(params).toString();
  return {
    promise: client =>
      client.get<GetPaymentsResponse>(
        `/mkt/transactions/payment_activity?${queryString}`
      ),
    types: [GET_PAYMENTS, GET_PAYMENTS_SUCCESS, GET_PAYMENTS_FAIL]
  };
}

export function resetPayments() {
  return {
    type: RESET_PAYMENTS
  };
}
