import {
  GetInviteeDataResponse,
  InvitationInitResponse,
  SubmitInviteSignupFormPayload,
  SubmitSignupFormPayload,
  UpdateSignupValuesPayload
} from 'types/actions/onboarding';
import { AsyncActionCreator } from 'types/redux';

export const SUBMIT_SIGNUP_FORM = 'onboarding/SUBMIT_SIGNUP_FORM';
export const SUBMIT_SIGNUP_FORM_SUCCESS =
  'onboarding/SUBMIT_SIGNUP_FORM_SUCCESS';
export const SUBMIT_SIGNUP_FORM_FAIL = 'onboarding/SUBMIT_SIGNUP_FORM_FAIL';

export const INVITE_FORM = 'onboarding/INVITE_FORM';
export const INVITE_FORM_SUCCESS = 'onboarding/INVITE_FORM_SUCCESS';
export const INVITE_FORM_FAIL = 'onboarding/INVITE_FORM_FAIL';

export const INVITEE_DETAIL = 'onboarding/INVITEE_DETAIL';
export const INVITEE_DETAIL_SUCCESS = 'onboarding/INVITEE_DETAIL_SUCCESS';
export const INVITEE_DETAIL_FAIL = 'onboarding/INVITEE_DETAIL_FAIL';

export const UPDATE_SIGNUP_VALUES = 'onboarding/UPDATE_SIGNUP_VALUES';

export function invitationInit(
  token
): AsyncActionCreator<InvitationInitResponse> {
  return {
    promise: client =>
      client.get<InvitationInitResponse>(
        `/mkt/users/invitation/accept?invitation_token=${token}`
      ),
    types: [INVITE_FORM, INVITE_FORM_SUCCESS, INVITE_FORM_FAIL]
  };
}

export function getInviteeData(
  token
): AsyncActionCreator<GetInviteeDataResponse> {
  return {
    promise: client =>
      client.get<GetInviteeDataResponse>(`/mkt/users/token_detail?id=${token}`),
    types: [INVITEE_DETAIL, INVITEE_DETAIL_SUCCESS, INVITEE_DETAIL_FAIL]
  };
}

export function submitSignupForm(data: SubmitSignupFormPayload) {
  return {
    promise: client => client.post('/mkt/users/sign_up', { data }),
    types: [
      SUBMIT_SIGNUP_FORM,
      SUBMIT_SIGNUP_FORM_SUCCESS,
      SUBMIT_SIGNUP_FORM_FAIL
    ]
  };
}

export function submitInviteSignupForm(data: SubmitInviteSignupFormPayload) {
  return {
    promise: client => client.put('/mkt/users/invitation', { data }),
    types: [
      SUBMIT_SIGNUP_FORM,
      SUBMIT_SIGNUP_FORM_SUCCESS,
      SUBMIT_SIGNUP_FORM_FAIL
    ]
  };
}

export function updateSignupValues(values: UpdateSignupValuesPayload) {
  return {
    result: values,
    type: UPDATE_SIGNUP_VALUES
  };
}
