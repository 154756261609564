import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as styles from './ResourceCard.module.scss';

interface Props {
  className?: string;
  'data-testid'?: string;
  label?: React.ReactNode;
  large?: boolean;
  loading?: boolean;
  title?: React.ReactNode;
  url?: string;
}

const ResourceCard = ({
  className = '',
  'data-testid': dataTestId = '',
  label = '',
  large = false,
  loading = false,
  title = '',
  url = ''
}: Props) => {
  return loading ? (
    <div className={styles.card}>
      <span className={styles.label}>
        <Skeleton />
      </span>
      <hr />
      <h6>
        <Skeleton />
        <Skeleton />
      </h6>
    </div>
  ) : (
    <a
      className={`${styles.card} ${large ? styles.large : ''} ${className}`}
      data-testid={dataTestId}
      href={url}
      key={url}
      rel="noreferrer"
      target="_blank"
    >
      <span className={styles.label}>{label}</span>
      <hr />
      <h6>{title}</h6>
    </a>
  );
};

export default ResourceCard;
