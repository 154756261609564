import { AnyAction } from 'redux';

import * as actions from '../actions/auth';
import * as onboardingActions from '../actions/onboarding';

const initialState = {
  afterLoginData: {},
  createLogin: true,
  createLoginData: {},
  fetchingAfterLoginPath: false,
  fetchLogin: true,
  invitationData: {},
  loaded: false,
  loading: true,
  loggingOut: false,
  loginData: {},
  resendingConfirmationEmail: false,
  resetPassword: false,
  sendingForgotEmail: false,
  sendingUnlockEmail: false,
  signupValues: {},
  submitting: false,
  user: null
};

export default (state = initialState, action: AnyAction = { type: null }) => {
  switch (action.type) {
    case actions.LOAD:
      return { ...state, loading: true };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        user: action.result.id ? action.result : null
      };

    case actions.LOAD_DEVICES_SUCCESS:
      return { ...state, devices: action.result };

    case onboardingActions.INVITE_FORM_SUCCESS:
      return {
        ...state,
        invitationData: action.result,
        invited: true,
        loaded: true,
        loading: false
      };

    case onboardingActions.INVITEE_DETAIL_SUCCESS:
      return {
        ...state,
        invited: true,
        invitee: action.result,
        loaded: true,
        loading: false
      };

    case onboardingActions.INVITEE_DETAIL_FAIL:
      return { ...state, invited: false, loaded: true, loading: false };

    case onboardingActions.INVITE_FORM_FAIL:
      return { ...state, errors: action.result, loaded: true, loading: false };

    case actions.START_SUBMISSION:
    case onboardingActions.SUBMIT_SIGNUP_FORM:
      return { ...state, submitting: true };

    case onboardingActions.SUBMIT_SIGNUP_FORM_SUCCESS:
      return { ...state, submitting: false };

    case onboardingActions.SUBMIT_SIGNUP_FORM_FAIL:
      return { ...state, submitting: false };

    case actions.LOAD_FAIL:
      return { ...state, errors: action.error, loaded: false, loading: false };

    case actions.RESEND_UNLOCK_EMAIL:
      return { ...state, sendingUnlockEmail: true };

    case actions.RESEND_UNLOCK_EMAIL_SUCCESS:
      return { ...state, sendingUnlockEmail: false };

    case actions.RESEND_UNLOCK_EMAIL_FAIL:
      return { ...state, sendingUnlockEmail: false };

    case actions.FORGOT_PASSWORD:
      return { ...state, loading: true, sentForgotEmail: false };

    case actions.RESEND_CONFIRMATION_EMAIL:
      return {
        ...state,
        resendingConfirmationEmail: true,
        sentConfirmationEmail: false
      };

    case actions.RESEND_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        resendingConfirmationEmail: false,
        sentConfirmationEmail: true
      };

    case actions.RESEND_CONFIRMATION_EMAIL_FAIL: {
      const errorMessage = action.error.body
        ? 'Email address ' + action.error.body.errors.email[0]
        : 'An error has occured';
      return {
        ...state,
        errorMessage,
        resendingConfirmationEmail: false,
        sentConfirmationEmail: false
      };
    }

    case actions.FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, sentForgotEmail: true };

    case actions.FORGOT_PASSWORD_FAIL: {
      const errorMessage = action.error.body
        ? 'Email address ' + action.error.body.errors.email[0]
        : 'An error has occured';
      return {
        ...state,
        errorMessage,
        loading: false,
        sentForgotEmail: false,
        sentForgotEmailFailed: true
      };
    }

    case actions.RESET_PASSWORD:
      return { ...state, resetPassword: true, resetPasswordComplete: false };

    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: false,
        resetPasswordComplete: true,
        resetPasswordResult: action.result
      };

    case actions.RESET_PASSWORD_FAIL:
      return {
        ...state,
        errorMessage: action.error.body,
        resetPassword: false,
        resetPasswordComplete: false
      };

    case actions.GET_SIGNIN_SUCCESS:
      return { ...state, googleLoginUrl: action.result.gplus_login_url };
    case actions.CREATE_SIGNIN:
      return {
        ...state,
        createdLogin: false,
        createLogin: true,
        fetchingAfterLoginPath: true
      };

    case actions.CREATE_SIGNIN_SUCCESS:
      return {
        ...state,
        createdLogin: true,
        createLogin: false,
        createLoginData: action.result.body,
        fetchingAfterLoginPath: false
      };

    case actions.CREATE_SIGNIN_FAIL:
      return {
        ...state,
        createdLogin: false,
        createLogin: false,
        createLoginErrors: action.error,
        fetchingAfterLoginPath: false
      };

    case actions.LOGOUT:
      return { ...state, loggedOut: false, loggingOut: true };

    case actions.LOGOUT_SUCCESS:
      return { ...state, loggedOut: true, loggingOut: false, user: null };

    case actions.LOGOUT_FAIL:
      return {
        ...state,
        errors: action.error,
        loggedOut: false,
        loggingOut: false
      };

    case onboardingActions.UPDATE_SIGNUP_VALUES:
      return {
        ...state,
        signupValues: { ...state.signupValues, ...action.result }
      };

    default:
      return state;
  }
};
