import { validations, yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields, tests } = validations;

export const addressValidation = yup.object().shape({
  address: fields.address.test(tests.noPoBox),
  address2: fields.address2.test(tests.noPoBox),
  city: fields.city,
  postal_code: fields.postalCode,
  state: fields.state
});

export const experienceSchema = yup.object().shape({
  investor_profile_attributes: yup.object().shape({
    investment_experience: fields.investmentExperience
  })
});

export const objectivesSchema = yup.object().shape({
  investor_profile_attributes: yup.object().shape({
    investment_objectives: fields.investmentObjectives,
    investment_reason: fields.investmentReason
  })
});

export const toleranceSchema = yup.object().shape({
  investor_profile_attributes: yup.object().shape({
    risk_tolerance: fields.riskTolerance
  })
});

export const nameSchema = yup.object().shape({
  first_name: fields.firstName,
  last_name: fields.lastName,
  middle_name: yupString.notRequired(),
  preferred_name: yupString.notRequired()
});

export const identitySchema = yup.object().shape({
  investor_profile_attributes: yup.object().shape({
    country_of_citizenship: fields.countryOfCitizenship,
    dob: fields.dob.test(tests.ageOver18),
    occupation: fields.occupation,
    residence_status: fields.residenceStatus,
    ssn: fields.ssn
  })
});

export const addressSchema = yup.object().shape({
  address_attributes: addressValidation
});

export const documentIdentificationSchema = yup.object().shape({
  id_document: yup.object().shape({
    attachment: fields.idDocument,
    expiry: fields.idExpiration,
    passport: yupString.when('type', {
      is: (val: string) => val === 'passport',
      then: () => yupString.required('Passport number is required')
    }),
    type: fields.idType
  })
});
