import {
  Button,
  DateSelect,
  Input,
  RadioButton,
  Select,
  Tooltip
} from '@equitymultiple/react-eui';
import FormError from 'components/FormError/FormError';
import {
  entityBusinessTypes,
  nonUSResidentBusinessTypes,
  portfolioValues,
  stateOptions
} from 'containers/Accounts/contents';
import { isComplete } from 'containers/Accounts/helpers';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  AnyInvestmentAccountType,
  BusinessClassification,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';

import BackLink from '../BackLink/BackLink';
import { EntityAccountFormValues } from './NewEntityAccount';

interface Props {
  backLinkRoute?: string;
  businessClass: string;
  businessClassifications: BusinessClassification[];
  control: Control<EntityAccountFormValues>;
  einVerificationOptional: boolean;
  errors: FieldErrors<EntityAccountFormValues>;
  investmentAccount?: LoadInvestmentAccountAccount<AnyInvestmentAccountType>;
  loading: boolean;
  residenceStatus: string;
  submitting: boolean;
}

const EntityAccountFields = ({
  backLinkRoute,
  businessClass,
  businessClassifications,
  control,
  einVerificationOptional,
  errors,
  investmentAccount,
  loading,
  residenceStatus,
  submitting
}: Props) => {
  const businessTypes =
    residenceStatus === 'Other'
      ? nonUSResidentBusinessTypes
      : entityBusinessTypes;

  let industries = [];
  if (!loading) {
    industries = businessClass
      ? businessClassifications.filter(
          businessClassification =>
            businessClassification.name === businessClass
        )[0].industries
      : businessClassifications[0].industries;
  }

  return (
    <>
      <h4 className="margin-top-0">Tell Us About Your Entity</h4>
      <p className="margin-xx">
        We'll collect your Entity account information. All of the information
        you provide is kept private and secure.
      </p>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.entity_name"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Entity Name" />
            )}
          />
        </Col>
        <Col className="overflow-visible" md={6}>
          <Controller
            control={control}
            name="investment_account.business_type"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Entity Type"
                options={businessTypes.map(key => ({
                  label: key.label,
                  value: key.value
                }))}
              />
            )}
          />
        </Col>
      </Row>

      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.officer_title"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Title" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.ein"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label={`EIN ${einVerificationOptional ? '(Optional)' : ''}`}
                mask="99-9999999"
              />
            )}
          />
        </Col>
      </Row>

      <Row className="margin-row">
        <Col>
          <Controller
            control={control}
            name="investment_account.date_of_registration"
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                label="State Business Registration Date"
              />
            )}
          />
        </Col>
      </Row>

      <Row className="margin-row">
        <Col>
          <Controller
            control={control}
            name="investment_account.date_of_formation"
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                label="Date of Formation"
              />
            )}
          />
        </Col>
      </Row>

      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.jurisdiction_of_business_registration"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State Where Organized"
                options={stateOptions}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.business_class"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Business Class"
                options={businessClassifications?.map(key => ({
                  label: key.name,
                  value: key.name
                }))}
              />
            )}
          />
        </Col>
      </Row>

      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.industry_class"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Industry"
                options={industries.map(key => ({
                  label: key.name,
                  value: key.name
                }))}
              />
            )}
          />
        </Col>
      </Row>

      <h4>What is the address of your Entity?</h4>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.address"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.address2"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address 2" />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={4}>
          <Controller
            control={control}
            name="investment_account.city"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col className="overflow-visible" md={4}>
          <Controller
            control={control}
            name="investment_account.region"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                options={stateOptions}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="investment_account.postal_code"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="99999"
              />
            )}
          />
        </Col>
      </Row>

      <h4>
        Next, we'll collect information about the entity through which you'd
        like to invest
      </h4>

      <h6>
        Is this Entity a retirement purpose vehicle?
        <Tooltip
          className="info-icon-margin-left"
          infoIcon
          tooltipContent="Please select this option if the entity is a 401K Plan or a checkbook access Individual Retirement Account."
        />
      </h6>
      <div
        className="margin-xx radio-group"
        data-testid="retirementPurposeVehicleRadios"
      >
        <Controller
          control={control}
          name="investment_account.retirement_purpose_vehicle"
          render={({ field }) => (
            <>
              <RadioButton
                {...setRadioFieldProps(field, errors, true)}
                hideError
                label="Yes"
              />
              <RadioButton
                {...setRadioFieldProps(field, errors, false)}
                label="No"
              />
            </>
          )}
        />
      </div>

      <h6>Is this entity publicly traded?</h6>
      <div
        className="margin-xx radio-group"
        data-testid="entityPubliclyTradedRadios"
      >
        <Controller
          control={control}
          name="investment_account.entity_publicly_traded"
          render={({ field }) => (
            <>
              <RadioButton
                {...setRadioFieldProps(field, errors, true)}
                hideError
                label="Yes"
              />
              <RadioButton
                {...setRadioFieldProps(field, errors, false)}
                label="No"
              />
            </>
          )}
        />
      </div>

      <h6>
        Is this entity presently involved in a bankruptcy, insolvency, or
        reorganization?
      </h6>
      <div
        className="margin-xx radio-group"
        data-testid="entityCurrentBankruptcyRadios"
      >
        <Controller
          control={control}
          name="investment_account.entity_current_bankruptcy"
          render={({ field }) => (
            <>
              <RadioButton
                {...setRadioFieldProps(field, errors, true)}
                hideError
                label="Yes"
              />
              <RadioButton
                {...setRadioFieldProps(field, errors, false)}
                label="No"
              />
            </>
          )}
        />
      </div>

      <Row className="margin-row overflow-visible">
        <Col md={6}>
          <Controller
            control={control}
            name="investment_account.entity_portfolio_value"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Portfolio Value"
                options={portfolioValues.map(key => ({
                  label: key,
                  value: key
                }))}
              />
            )}
          />
        </Col>
      </Row>

      <FormError errors={errors} />

      <div className="forwardBackButtonWrapCompact">
        <Button
          loading={submitting}
          style={{ width: 160 }}
          type="submit"
          variant="orange"
        >
          {isComplete(investmentAccount) ? 'Update' : 'Continue'}
        </Button>
        <BackLink
          investmentAccount={investmentAccount}
          page="Entity"
          route={backLinkRoute}
        />
      </div>
    </>
  );
};

export default EntityAccountFields;
