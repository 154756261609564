import { AnyAction } from 'redux';

import * as actions from '../actions/portfolio';
const initialState = {
  account: null,
  accountStatement: null,
  articles: [],
  dashboard: {
    metrics: {}
  },
  generatingAccountStatement: false,
  investment: null,
  loadedAccount: false,
  loadedInvestment: false,
  loadingAccount: false,
  loadingArticles: false,
  loadingDashboard: false,
  loadingInvestment: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.GET_DASHBOARD:
      return {
        ...state,
        loadingDashboard: true
      };

    case actions.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.result,
        loadingDashboard: false
      };

    case actions.GET_DASHBOARD_FAIL:
      return {
        ...state,
        loadingDashboard: false
      };

    case actions.GET_ACCOUNT:
      return {
        ...state,
        loadingAccount: true
      };

    case actions.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.result,
        loadedAccount: true,
        loadingAccount: false
      };

    case actions.GET_ACCOUNT_FAIL:
      return {
        ...state,
        loadingAccount: false
      };

    case actions.CLEAR_ACCOUNT:
      return {
        ...state,
        account: null
      };

    case actions.GET_INVESTMENT:
      return {
        ...state,
        loadingInvestment: true
      };

    case actions.GET_INVESTMENT_SUCCESS:
      return {
        ...state,
        investment: action.result,
        loadedInvestment: true,
        loadingInvestment: false
      };

    case actions.GET_INVESTMENT_FAIL:
      return {
        ...state,
        loadingInvestment: false
      };

    case actions.CLEAR_INVESTMENT:
      return {
        ...state,
        investment: null
      };

    case actions.GENERATE_ACCOUNT_STATEMENT:
      return {
        ...state,
        generatingAccountStatement: true
      };

    case actions.GENERATE_ACCOUNT_STATEMENT_FAIL:
      return {
        ...state,
        generatingAccountStatement: false
      };

    case actions.GENERATE_ACCOUNT_STATEMENT_SUCCESS:
      return {
        ...state,
        accountStatement: {
          filename: action.result.filename,
          url: action.result.url
        },
        generatingAccountStatement: false
      };

    case actions.CLEAR_ACCOUNT_STATEMENT:
      return {
        ...state,
        accountStatement: null
      };

    default:
      return state;
  }
};
