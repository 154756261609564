import { Button, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import FormError from 'components/FormError/FormError';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';
import { InferType } from 'yup';

import { riskToleranceOptions } from '../content';
import { getUserAttributes, profileFormValues } from '../helpers';
import { ProfileValues, SharedProfileValues } from '../types';
import { toleranceSchema } from '../validation';

const Tolerance = ({ dispatch, user }: SharedProfileValues) => {
  const navigate = useNavigate();

  const defaultValues = getUserAttributes(user) as ProfileValues;

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(toleranceSchema),
    values: defaultValues
  });

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit: SubmitHandler<InferType<typeof toleranceSchema>> = values => {
    const submitValues = profileFormValues(values, [
      'investor_profile_attributes.risk_tolerance'
    ]);

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/users/profile/name');
        });
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  return (
    <AccountProgressContainer showSkipLink signupStage="profile">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Risk Tolerance</h3>
        <p className="margin-xx">
          Let us know your investment preferences to help us personalize your
          financial journey. Your choices guide our recommendations and ensure
          we align our services with your goals and risk tolerance.
        </p>
        <p>My risk tolerance is:</p>
        <Controller
          control={control}
          name="investor_profile_attributes.risk_tolerance"
          render={({ field }) => (
            <Select
              {...setFieldProps(field, errors)}
              options={riskToleranceOptions}
              placeholder="Select"
            />
          )}
        />

        <FormError errors={errors} />

        <div className="forwardBackButtonWrapCompact">
          <Button
            className="button-fixed-width margin-top-xx"
            loading={isSubmitting}
            type="submit"
            variant="orange"
          >
            Continue
          </Button>
          <Button
            className="button-fixed-width margin-top-xx arrowBackButton"
            data-testid="backLink"
            disabled={isSubmitting}
            variant="outlined"
            wrapper={<Link to="/users/profile/objectives" />}
          >
            <Back />
          </Button>
        </div>
      </form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Tolerance);
