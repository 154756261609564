import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import { updateUsername } from 'redux/actions/user-settings';
import { User } from 'types/actions/auth';
import { UpdateUsernamePayload } from 'types/actions/user-settings';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';
import { InferType } from 'yup';

import { updateEmailSchema } from '../validation';

interface Props {
  dispatch: Dispatch;
  user: User;
}

const SettingUpdateEmailForm = ({ dispatch, user }: Props) => {
  const unconfirmedEmail = user?.unconfirmed_email;

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(updateEmailSchema),
    values: {
      unconfirmed_email: unconfirmedEmail || ''
    }
  });

  const onSubmit: SubmitHandler<
    InferType<typeof updateEmailSchema>
  > = values => {
    return dispatch(updateUsername({ user: values } as UpdateUsernamePayload))
      .then(res => {
        humane.notice(res.message);
        dispatch(loadAuth());
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {unconfirmedEmail ? (
        <div data-testid="unconfirmedMessage">
          <p>
            Your request to update your email is registered. Confirmation link
            has been sent to your new email {unconfirmedEmail}. Kindly confirm
            your new email to update.
          </p>
          <p>
            Press resend button to send the confirmation instructions again.
          </p>
        </div>
      ) : (
        <p>
          <strong className="text-blue">Note: </strong> You have to confirm your
          new email.
        </p>
      )}
      <Controller
        control={control}
        name="unconfirmed_email"
        render={({ field }) => (
          <Input {...setFieldProps(field, errors)} label="New Email" />
        )}
      />
      <FormError errors={errors} />
      <Button
        className="float-right"
        data-testid="updateEmailButton"
        loading={isSubmitting}
        type="submit"
      >
        {unconfirmedEmail ? 'Resend' : 'Update Email'}
      </Button>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(SettingUpdateEmailForm);
