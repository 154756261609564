import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import FormError from 'components/FormError/FormError';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';
import { InferType } from 'yup';

import { getUserAttributes, profileFormValues } from '../helpers';
import { ProfileValues, SharedProfileValues } from '../types';
import { nameSchema } from '../validation';

const Name = ({ dispatch, user }: SharedProfileValues) => {
  const navigate = useNavigate();

  const defaultValues = getUserAttributes(user) as ProfileValues;

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(nameSchema),
    values: defaultValues
  });

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit: SubmitHandler<InferType<typeof nameSchema>> = values => {
    const submitValues = profileFormValues(values, [
      'first_name',
      'last_name',
      'middle_name',
      'preferred_name'
    ]);

    // Submit empty strings for these fields so we can check for an empty string vs. null
    // to determine if the user has completed this form already or not
    if (!submitValues.middle_name) submitValues.middle_name = '';
    if (!submitValues.preferred_name) submitValues.preferred_name = '';

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/users/profile/kyc');
        });
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  return (
    <AccountProgressContainer showSkipLink signupStage="profile">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Let's verify your identity</h3>
        <p className="margin-xx">
          We collect this information to comply with federal regulation such as
          know-your-customer (KYC) and anti-money laundering (AML) laws.
        </p>
        <Row>
          <Col md={6} xs={12}>
            <Controller
              control={control}
              name="first_name"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Legal First Name"
                />
              )}
            />
          </Col>
          <Col md={6} xs={12}>
            <Controller
              control={control}
              name="last_name"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Legal Last Name"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <Controller
              control={control}
              name="middle_name"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Legal Middle Name (Optional)"
                />
              )}
            />
          </Col>
          <Col md={6} xs={12}>
            <Controller
              control={control}
              name="preferred_name"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Preferred Name (Optional)"
                />
              )}
            />
          </Col>
        </Row>

        <FormError errors={errors} />

        <div className="forwardBackButtonWrapCompact">
          <Button
            className="button-fixed-width margin-top-xx"
            loading={isSubmitting}
            type="submit"
            variant="orange"
          >
            Continue
          </Button>
          <Button
            className="button-fixed-width margin-top-xx arrowBackButton"
            data-testid="backLink"
            disabled={isSubmitting}
            variant="outlined"
            wrapper={<Link to="/users/profile/tolerance" />}
          >
            <Back />
          </Button>
        </div>
      </form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Name);
