import {
  DateSelect,
  FileUploader,
  Input,
  Select
} from '@equitymultiple/react-eui';
import { idTypes } from 'containers/Accounts/contents';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { maxUploadSize } from 'utilities/constants';
import { setFieldProps } from 'utilities/formHelpers';

import { ProfileFormValues } from '../Profile/Profile';

interface Props {
  control: Control<ProfileFormValues>;
  defaultValues?: ProfileFormValues;
  errors: FieldErrors<ProfileFormValues>;
  isSubmitting: boolean;
  setValue: (field, value) => void;
  watch: (field: string) => string;
}

const PersonalIdentificationFields = ({
  control,
  defaultValues,
  errors,
  isSubmitting,
  setValue,
  watch
}: Props) => {
  const [hasNewDoc, setHasNewDoc] = useState(false);

  const idType = watch('id_document.type');

  const handleFileRemoval = () => {
    setValue('id_document.attachment', null);
    setHasNewDoc(true);
  };

  return (
    <>
      <h6>Personal Identification Document</h6>
      <Controller
        control={control}
        name="id_document.attachment"
        render={({ field }) => (
          <FileUploader
            {...setFieldProps(field, errors)}
            acceptedFileTypes={['JPG', 'PNG']}
            existingFile={
              !hasNewDoc &&
              (defaultValues.id_document?.attachment as Partial<File> & {
                url: string;
              })
            }
            maxSize={maxUploadSize}
            onRemove={handleFileRemoval}
            showImage={hasNewDoc}
            upload={(uploadData: File) => field.onChange(uploadData)}
            uploading={isSubmitting}
          />
        )}
      />
      <Row style={{ marginTop: '-10px' }}>
        <Col md={6}>
          <Controller
            control={control}
            name="id_document.type"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Document Type"
                options={idTypes}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="id_document.expiry"
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                data-testid="idDocumentExpiry"
                futureDate
                label="ID Expiration Date"
              />
            )}
          />
        </Col>
      </Row>
      {idType === 'passport' && (
        <Row>
          <Col md={6}>
            <Controller
              control={control}
              name="investor_profile_attributes.passport"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Passport Number"
                />
              )}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default PersonalIdentificationFields;
